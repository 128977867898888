import React, { useEffect, useState } from 'react';
import './App.css';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { LoadExperience } from './pages/load-experience';
import { ListExperiences } from './pages/list-experiences';

import { ExperienceProvider } from './contexts/ExperienceContext';

import UserModel from './models/UserModel';
import Header from './components/header';
import LandingPage from './components/landing-page';
import Footer from './components/footer';
// import { } from './services/firebase';
import * as queryString from 'query-string';
import { ToastContainer } from 'react-toastify';
import LogginPageMain from './components/loggin-page-main';
import Success from './components/success';

import { ModalProvider } from './contexts/ModalContext';
import { getExperienceJsonData } from './services/experience-upload.service';
import ExperienceModel from './models/ExperienceModel';
import { Spinner } from 'react-bootstrap';

if (process.env.NODE_ENV === "production") {
  console.log = () => {
    // forward logs to nothing to not spill secrets in production
  }
  console.warn = () => {
    // forward logs to nothing to not spill secrets in production
  }
}

const App: React.FC = () => {

  const [userLoggedIn] = useState(false);
  const [userModel, setUserModel] = useState<UserModel>();
  const [loading, setLoading] = useState<boolean>(true);
  const [experience, setExperience] = useState<ExperienceModel | undefined>();

  // const auth = useAuth();

  // useEffect(() => {
  //   localStorage.setItem('experience', '');
  //   localStorage.setItem('qrCodeResult', '');
  //   localStorage.setItem('step', 'success');
  //   localStorage.setItem('step', 'target-image');
  //   localStorage.setItem('isEditing', '');
  //   localStorage.setItem('originalExperienceName', '');
  //   localStorage.setItem('products', "");
  // }, [])

  useEffect(() => {
    setUserModel({ id: "QRFirst", name: "QR First", email: "qrcode@perk-lab.com" } as any)
  }, [])

  useEffect(() => {

    if (userModel !== undefined) {
      const parsed = queryString.parse(window.location.search);
      const experienceId = parsed.uuid
      console.log('experience id', experienceId)
      if (experienceId) {
        // check if experience exists
        const email = userModel?.email
        const downloadUrl = `https://storage.googleapis.com/ar-tee.appspot.com/exp/${email}/${experienceId}`;
        getExperienceJsonData(downloadUrl).then((response) => {
          console.log('response', response)
          if (response) {
            // we found the experience. Open the player
            window.location.href = `${process.env.REACT_APP_ComeToLifeUrl}/?exp=${downloadUrl}`
          } else {
            // we didn't find the experience. Open the editor and prefill with exp Id as name
            const exp = new ExperienceModel();
            exp.uuid = experienceId as string;
            setExperience(exp);
            // forward to edit experience
            window.location.href = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/edit-experience/' + experienceId
          }
        })
      } else {
        setLoading(false)
      }
    }
  }, [userModel])

  const spinner =
    <Spinner className="spinnerCircle" animation="border" role="status">
      <span className="sr-only">Loading</span>
    </Spinner>

  if (loading) {
    return (
      <div className="App">
        <div className="mainSpinner">
          {spinner}
        </div>
      </div>
    )
  }

  return (
    <div className="App" >

      <ExperienceProvider user={userModel} setUserModels={setUserModel}>
        <ModalProvider>
          <ToastContainer />
          {userLoggedIn ? (
            <>
              <BrowserRouter>
                <Header user={userModel} />
                <Route exact path='/edit-experience/:id' component={
                  () => <LoadExperience user={userModel} setUserModels={setUserModel} experience={experience} />
                } />

                <Route exact path='/create-experience' component={
                  () => <LoadExperience user={userModel} setUserModels={setUserModel} />
                } />

                <Route exact path='/create-experience/success' component={
                  () => <Success user={userModel} setUserModels={setUserModel} />
                } />

                <Route exact path='/login' component={
                  () => <LogginPageMain />
                } />

                <Route exact path='/' component={
                  () => <ListExperiences user={userModel} setExperience={setExperience} />
                } />

              </BrowserRouter>
            </>
          ) : (null)}

          {!userLoggedIn ? (
            <>
              <BrowserRouter>
                <Header user={userModel} />
                <Route exact path='/create-experience' component={() => <LoadExperience user={userModel} setUserModels={setUserModel} />} />
                <Route exact path='/login' component={() => <LogginPageMain />} />

                <Route exact path='/create-experience/success' component={
                  () => <Success user={userModel} setUserModels={setUserModel} />
                } />
                <Route exact path='/edit-experience/:id' component={
                  () => <LoadExperience user={userModel} setUserModels={setUserModel} />
                } />

                <Route exact path='/' component={() => (<LandingPage />)} />
              </BrowserRouter>
            </>
          ) : (null)}
          <Footer />
        </ModalProvider>
      </ExperienceProvider>

    </div>
  );
}

export default App;
