import Compressor from 'compressorjs';
import isDevelopment, { getImageTrackerProUrl } from './utilities';
import { v4 as uuidv4 } from 'uuid';

const compressAndUploadImageFile = function (
  file: Blob,
  setNtfProcessingInProgress: any,
  handleNtfProcessingComplete: any
) {
  const max = 900;
  const img = document.createElement('img');
  img.onload = () => {
    const height = img.height;
    const width = img.width;
    if (height > max || width > max) {
      if (height > width) {
        new Compressor(file, {
          quality: 0.8,
          maxHeight: max,
          success(result) {
            postFile(
              result,
              setNtfProcessingInProgress,
              handleNtfProcessingComplete
            );
          },
          error(err) {
            console.error(err.message);
          }
        });
      } else {
        new Compressor(file, {
          quality: 0.8,
          maxWidth: max,
          success(result) {
            postFile(
              result,
              setNtfProcessingInProgress,
              handleNtfProcessingComplete
            );
          },
          error(err) {
            console.error(err.message);
          }
        });
      }
    } else {
      if (file !== undefined) {
        postFile(file, setNtfProcessingInProgress, handleNtfProcessingComplete);
      } else {
        setNtfProcessingInProgress(false, '', 'File is undefined');
      }
    }
  };
  img.src = window.URL.createObjectURL(file);
};

const postFile = function (
  compressedFile: Blob,
  setNtfProcessingInProgress: any,
  handleNtfProcessingComplete: any
) {
  const url = getImageTrackerProUrl();

  let fileName = '';
  if (isDevelopment()) {
    fileName = 'development.nft-' + uuidv4();
  } else {
    fileName = 'nft-' + uuidv4();
  }

  const formData = new FormData();
  formData.append('upload', compressedFile);
  formData.append('fileName', fileName);

  setNtfProcessingInProgress(true);

  if (url !== null && url !== undefined) {
    return fetch(url, {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        setNtfProcessingInProgress(false);

        if (response) {
          if (response != null) {
            if (response.ok) {
              response.json().then((data) => {
                handleNtfProcessingComplete(
                  true,
                  'Ntf tracking was successful.',
                  data.trackableUrl
                );
              });
            } else {
              handleNtfProcessingComplete(
                false,
                'There was an error upload file.',
                ''
              );
            }
          } else {
            handleNtfProcessingComplete(
              false,
              'There was an error upload file.',
              ''
            );
          }
        } else {
          handleNtfProcessingComplete(
            false,
            'There was an error upload file.',
            ''
          );
        }
      })
      .catch(() => {
        handleNtfProcessingComplete(
          false,
          'There was an error upload file.',
          ''
        );
      });
  }
};

export { compressAndUploadImageFile as default };
