import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';

/*
const getFireBaseApiKey = function() {
  let value = "";
  if (process !== undefined && process !== null) {
    if (process.env !== undefined && process.env !== null) {
      if (process.env.REACT_APP_FireBase_apiKey !== undefined && process.env.REACT_APP_FireBase_apiKey !== null) {
        value = process.env.REACT_APP_FireBase_apiKey.toString();
      }
    }
    return value;
  }
}

const getFireBaseAuthDomain = function() {
  let value = "";
  if (process !== undefined && process !== null) {
    if (process.env !== undefined && process.env !== null) {
      if (process.env.REACT_APP_FireBase_authDomain !== undefined && process.env.REACT_APP_FireBase_authDomain !== null) {
        value = process.env.REACT_APP_FireBase_authDomain.toString();
      }
    }
    return value;
  }
}

const getFireBaseDatabaseUrl = function() {
  let value = "";
  if (process !== undefined && process !== null) {
    if (process.env !== undefined && process.env !== null) {
      if (process.env.REACT_APP_FireBase_databaseURL !== undefined && process.env.REACT_APP_FireBase_databaseURL !== null) {
        value = process.env.REACT_APP_FireBase_databaseURL.toString();
      }
    }
    return value;
  }
}

const getFireBaseStorageBucket = function() {
  let value = "";
  if (process !== undefined && process !== null) {
    if (process.env !== undefined && process.env !== null) {
      if (process.env.REACT_APP_FireBase_storageBucket !== undefined && process.env.REACT_APP_FireBase_storageBucket !== null) {
        value = process.env.REACT_APP_FireBase_storageBucket.toString();
      }
    }
    return value;
  }
}

// var firebaseConfig = {
//     apiKey: getFireBaseApiKey(),
//     authDomain: getFireBaseAuthDomain(),
//     databaseURL: getFireBaseDatabaseUrl(),
//     storageBucket: getFireBaseStorageBucket(),
//   };
*/
const firebaseConfig = {
  apiKey: 'AIzaSyA-cTPA3d-W9o5dDFmxG3Qmr7sh2FMqGgI',
  authDomain: 'ar-tee.firebaseapp.com',
  databaseURL: 'https://ar-tee.firebaseio.com',
  projectId: 'ar-tee',
  storageBucket: 'ar-tee.appspot.com',
  messagingSenderId: '573002725914',
  appId: '1:573002725914:web:d5760742dd7f3aa4baefa3',
  measurementId: 'G-P45RWCV360'
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const db = firebase.firestore();
export const firebaseUi = new firebaseui.auth.AuthUI(firebase.auth());

export const auth = firebase.auth();

interface Price {
  id: string;
  active: boolean;
  currency: string;
  interval: 'month' | 'year' | null;
  quantity: number;
  type: 'recurring' | 'one_time';
  unit_amount: number;
}

interface Metadata {
  features: string;
  firebaseRole: string;
}

export interface Product {
  active: boolean;
  description: string;
  images: string[];
  name: string;
  role: 'basic' | 'prime';
  // prices: Record<string, Price | undefined>
  prices: Record<string, Price>;
  metadata: Metadata;
}

export const fetchProducts = async () => {
  const prices: firebase.firestore.DocumentData = {};
  // const products: firebase.firestore.DocumentData[] = [];
  const products: Product[] = [];
  const querySnapshot = await db
    .collection('products')
    // .where('active', '==', true)
    .where('name', '!=', 'Evaluation MiXR - early bird')
    .get();

  if (querySnapshot) {
    querySnapshot.forEach(async (doc) => {
      const priceSnap = await doc.ref
        .collection('prices')
        .where('active', '==', true)
        .orderBy('unit_amount')
        .get();

      const product: Product = doc.data() as any;
      product.description = product.description?.toUpperCase() || '';
      product.prices = {};
      products.push(product);
      // Prices
      priceSnap.docs.forEach((doc) => {
        const priceId = doc.id;
        const priceData = doc.data();
        prices[priceId] = priceData;
        product.prices[priceId] = priceData as Price;
      });
    });
    return products;
  }
};
fetchProducts().then((products) => console.log(products));

export { storage, db };
