import React, { useCallback, useContext, useEffect, useState } from 'react';
import '../App.css';
import { Spinner } from 'react-bootstrap';
import { getExperiencesByEmail } from 'src/services/experience-upload.service';
import { StorageService } from 'src/services/StorageService';
import { Constants } from 'src/models/Constants';
import UserModel from 'src/models/UserModel';
import { useHistory } from "react-router-dom";
import getPreviewUrl, { getStorageUrl } from 'src/services/config-reader';


import './styles/list-experiences.css';
import useProductsList from 'src/services/productsService';
import QRCodeComponent from 'src/components/qrCode';
import { ExperienceContext } from 'src/contexts/ExperienceContext';

import hero from '../assets/images/hero.png';
import ExperienceModel from 'src/models/ExperienceModel';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
    user?: UserModel;
    setExperience: (experience: ExperienceModel | undefined) => any;
}

export const ListExperiences: React.FC<IProps> = ({ user, setExperience }) => {

    const { deleteLocalStore } = useContext(ExperienceContext);

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [listCalleMade, setListCalleMade] = useState(false);
    const [experienceModelsLocal, setExperienceModelsLocal] = useState<ExperienceModel[] | undefined>();
    const [firstName, setFirstName] = useState("");

    const products = useProductsList();

    const getFileIdOnly = useCallback((url: string, finalCharacter: string) => {

        let indeOf = url.lastIndexOf(finalCharacter);
        let nextIndex = 0;
        while (nextIndex >= 0) {
            indeOf = url.lastIndexOf(finalCharacter);
            url = url.substring(indeOf + 1);
            nextIndex = url.indexOf(finalCharacter);
        }

        console.log("PRODUCTS", products);

        return url as string;
    }, [products])

    const previewButtonClick = (event: any) => {
        const fileName: string = event.target.id;
        const expUrl = `${getPreviewUrl()}/?exp=${getStorageUrl()}${process.env.REACT_APP_Experience_Store}/${fileName}`
        window.open(expUrl)
    }

    const handleViewButtonClick = function (event: any) {
        localStorage.setItem("experience", "");
        localStorage.setItem("step", "target-image");
        const id = event.target.id;
        setExperience(experienceModelsLocal![id])
        localStorage.setItem(Constants.ExperienceListKey, JSON.stringify(experienceModelsLocal));
        history.push('/edit-experience/' + experienceModelsLocal![id].uuid);
    }

    useEffect(function () {
        const updateList = async () => {

            const user = StorageService.Get(Constants.UserKey);


            if (user) {
                const userModel = JSON.parse(user) as UserModel;
                if (userModel) {
                    setFirstName(userModel.firstName)
                    const experienceResponse = await getExperiencesByEmail(userModel.email);

                    if (experienceResponse) {
                        console.log('experience Response', experienceResponse)
                        if (experienceResponse.success) {
                            setExperienceModelsLocal(experienceResponse.experiences);
                            const listJson = JSON.stringify(experienceResponse.experiences)
                            StorageService.Store(Constants.ExperienceListKey, listJson);
                            setIsLoaded(true);
                        } else {
                            setErrorOccured(true);
                        }
                    }
                } else {
                    //TODO - redirect to login flow - need to implement a route gaurd 
                }
            } else {
                //TODO - redirect to login flow - need to implement a route gaurd 
            }
        }

        if (!listCalleMade) {
            setListCalleMade(true);
            (async () => {
                setIsLoading(true);
                await updateList();
                setIsLoading(false);
            })();
        }
    }, [
        setListCalleMade,
        listCalleMade,
        getFileIdOnly
    ])

    const spinner =
        <Spinner className="spinnerCircle" animation="border" role="status">
            <span className="sr-only">Getting your epxeriences</span>
        </Spinner>

    const errorMessage =
        <p style={{ marginTop: '10px' }}>
            Sorry {firstName}, we had a problem getting you experience data.
            Pleas contact support and we'll aim to get it sorted.
        </p>

    return (
        <div className="experiencesContainer">

            {isLoading ? (
                <div className="loading">
                    {spinner}
                </div>
            ) : (null)}

            {!isLoading && experienceModelsLocal?.length !== 0 ? (
                <>
                    <header>
                        <h1>Experiences</h1>

                        {isLoaded && (
                            <a href="/create-experience">
                                <button className="listCreateButton" onClick={deleteLocalStore}>
                                    CREATE
                                </button>
                            </a>
                        )}

                    </header>

                    <p>
                        Select from one of the experiences you've
                        created before to view or edit
                        or click the "Create" to start a new one.
                    </p>

                    {experienceModelsLocal && experienceModelsLocal.length >= 5 && (
                        <p className="maximumOfExperiences">
                            You reached the maximum number of
                            experiences allowed for your subscription.
                            In the next experience, you will need
                            to choose a publishing option.
                        </p>
                    )}


                    <div className="experienceCardsContainer">

                        {/* {experienceModelsLocal.length >= 5 && (
                            <p className="maximumOfExperiences">You reached the maximum of experiences. <br />
                                <a className="maximumOfExperiencesMail" href="mailto:info@tripod-digital.co.nz">Please contact us.</a></p>
                        )} */}

                        <h2>List of Experiences</h2>

                        {experienceModelsLocal?.map((value: ExperienceModel | undefined, index) => {
                            return (
                                <div className="experienceCardBody" key={index}>
                                    <p>{value?.title}</p>
                                    <aside>
                                        <button
                                            id={value?.uuid}
                                            onClick={previewButtonClick}>
                                            VIEW
                                        </button>
                                        {user?.stripeRole === "basic" && (
                                            <button
                                                id={index + ''}
                                                onClick={handleViewButtonClick}>
                                                EDIT
                                            </button>
                                        )}
                                        <QRCodeComponent expId={value!.uuid} />
                                    </aside>
                                </div>
                            )
                        })}
                    </div>
                </>
            ) : (null)}

            {(isLoaded && experienceModelsLocal?.length === 0) ? (
                <main className="noExperiences">
                    <img src={hero} alt="MiXR Hero" />
                    <aside>
                        <h1> <strong>Oops... </strong><br />
                            You don't have experiences yet...<br /><br />
                            <strong>Let's</strong> create one?</h1>
                        <a href="/create-experience">
                            <button onClick={deleteLocalStore}>START CREATING</button>
                        </a>
                    </aside>
                </main>
            ) : (null)}

            {errorOccured ? (
                <div>
                    {errorMessage}
                </div>
            ) : (null)}
        </div>
    )
}

export default ListExperiences;
