import { TrackableSpec } from "./ExperienceModel";

export default class UploadImageFileModel implements TrackableSpec {
    id = ""; 
    name = "";
    url = "";
    // Index of video in video-array
    video?: number;
    videoId = ""; 
  popUpFile = "";
  image?: number;
  
  manual_close?: boolean = false;

    fileUploadedSuccessfully = false;
    fileUploadError = false;
    stillUploading = false;

    selectedFile: any;
    selectedFilDisplay = "";
}