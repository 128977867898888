import React, { useContext } from 'react';
import { ExperienceContext } from "src/contexts/ExperienceContext";

const ExperienceStep: React.FC = () => {
  const {
    createExperienceStep,
    experience
  } = useContext(ExperienceContext);

  return (
    <>
      {createExperienceStep === "target-image" && (
        <p id="step">Step 1 out of 4</p>
      )}

      {(createExperienceStep === "filters" &&
        experience.videos.length === 0) && (
          <p id="step">Step 2 out of 4</p>
        )}

      {(createExperienceStep === "publish" ||
        (createExperienceStep === "filters" && experience.videos.length > 0) ||
        createExperienceStep === "personalize" ||
        createExperienceStep === "subscription") && (
          <p id="step">Step 3 out of 4 (Almost there!)</p>
        )}

      {createExperienceStep === "success" && (
        <p id="step">Step 4 out of 4</p>
      )}
    </>

  )
}

export default ExperienceStep;