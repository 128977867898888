import React, { createContext, useState, ReactNode } from 'react'


interface ModalProviderProps {
  children: ReactNode;
}

interface ModalContextData {
    modalIsOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    setModalIsOpen: (status: boolean) => void;
    customStyles: any;
}

export const ModalContext = createContext({} as ModalContextData)

export function ModalProvider({ children }: ModalProviderProps) {
    
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function openModal() {
        setModalIsOpen(true);
    }

  
    function closeModal(){
        setModalIsOpen(false);
    }

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };


  return (
    <ModalContext.Provider
      value={{
        modalIsOpen,
        setModalIsOpen,
        openModal,
        closeModal,
        customStyles
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}