import React, { useState, useEffect, useCallback, useContext, useRef, useLayoutEffect } from 'react';
import ExperienceModel from 'src/models/ExperienceModel';
import { Spinner } from 'react-bootstrap';
// import { DatePicker } from "./DatePickerComponent";
import { LogoUploadButton } from './LogoUploadButton.';
import {
  compressFile,
  uploadToStorage
} from 'src/services/upload-to-storage.service';
import { ExperienceFileNameService } from 'src/services/experience-file-name.service';

// import { ColorPickerPopupView } from "./ColorPickerPopupView";
import { StorageService } from 'src/services/StorageService';
import { Constants } from 'src/models/Constants';
import UserModel from 'src/models/UserModel';
// import { LogoPopupView } from '../experience-details/LogoPopupView';

// import HEX2RGB from '../../services/hexToRGB';

import './styles/experience-details.css';
import { uploadExperience } from 'src/services/experience-upload.service';
import { iconColor } from "src/config";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, Range } from 'react-date-range';
import { ExperienceContext } from 'src/contexts/ExperienceContext';
import { ModalContext } from 'src/contexts/ModalContext';

interface IProps {
  experienceModel: ExperienceModel;
  createExperienceStep: string;
  setCreateExperienceStep: (step: string) => void;
  user: UserModel;
}

export const ExperienceDetailsComponent: React.FC<IProps> = ({
  experienceModel,
  setCreateExperienceStep,
  user
}) => {

  const { saveExperienceToLocalStore } = useContext(ExperienceContext);
  const { setModalIsOpen } = useContext(ModalContext);

  const [experienceLogoLoading, setExperienceLogoLoading] = useState(false);
  const [brandColor, setBrandColor] = useState(experienceModel.brandColor);
  const [duration, setDuration] = useState(experienceModel.duration);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateSet, setStartDateSet] = useState(false);
  const [logoFileUrl, setFileUrl] = useState('');
  const [logoDownloaded, setLogoDownloaded] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      key: 'selection'
    }
  ]);

  const [uplodaingExperiencLogo, setUplodaingExperiencLogo] = useState(false);
  const [
    uplodaingExperiencLogoError,
    setUplodaingExperiencLogoError
  ] = useState(false);

  if (experienceModel.startdate && !startDateSet) {
    setStartDateSet(true);
    setStartDate(new Date(experienceModel.startdate));
  }

  const onFileSelected = async (event: any) => {
    setExperienceLogoLoading(true)

    if (event.target.files[0]) {
      const file = event.target.files[0];
      const type = file.type;

      const ext = type.substr(type.length - 4);
                ///////////////
          const result = await compressFile(file);

          // if (!experienceModel.img1) {
          const user = StorageService.Get(Constants.UserKey);
          const fileBlob = result as Blob;

      switch (ext) {
        case '/jpg':
        case 'jpeg':
        case '/png':


          if (user) {
            const userModel = JSON.parse(user) as UserModel;
            if (userModel) {

              const newFileNameResponse = ExperienceFileNameService.BuildNewLogoFilename(type, userModel.email);
              if (newFileNameResponse.success) {
                experienceModel.img1 = newFileNameResponse.filePath;
                saveExperienceToLocalStore(experienceModel);
              } else {
                setUplodaingExperiencLogoError(true);
              }
            }
          } else {
            setUplodaingExperiencLogoError(true);
          }

          if (!user) {
            const guestUser = new UserModel();

            guestUser.id = "H7lEx9Y1hIa6aaCcazjDO8wWWkl1"
            guestUser.name = "Guest";
            guestUser.firstName = "Guest";
            guestUser.lastName = "NoUser";
            guestUser.email = "guest@email.com";
            guestUser.loginProvider = "email";
            guestUser.accessToken = "H7lEx9Y1hIa6aaCcazjDO8wWWkl1";
            guestUser.firebaseUser = null;

            const newFileNameResponseGuest = ExperienceFileNameService.BuildNewLogoFilename(type, guestUser.email);
            if (newFileNameResponseGuest.success) {
              experienceModel.img1 = newFileNameResponseGuest.filePath;
              saveExperienceToLocalStore(experienceModel);
            } else {
              setUplodaingExperiencLogoError(true);
            }
          }


          if (fileBlob) {

            const uploadResult = await uploadToStorage(fileBlob, experienceModel.img1);

            if (uploadResult) {
              setUplodaingExperiencLogo(false);

              if (uploadResult && uploadResult.success) {
                experienceModel.img1 = uploadResult.downloadUrl;
                saveExperienceToLocalStore(experienceModel);

                const localCopyOfFileUrl = window.URL.createObjectURL(file);
                setFileUrl(localCopyOfFileUrl);
              }

              if (uploadResult && !uploadResult.success) {
                setUplodaingExperiencLogoError(true);
              }

            } else {
              setUplodaingExperiencLogoError(true);
            }
          }
          setExperienceLogoLoading(false)
          break;
        default:

          alert("File not accepted");
      }
    }
    setExperienceLogoLoading(false)
  }

  const handleColorChange = (event: any) => {
    const color = event;
    setBrandColor(color);
    experienceModel.brandColor = color;
  };

  const windowClick = useCallback((event) => {
    // event.preventDefault();
    // console.log('click', event.target)
    const calendarDiv = document.getElementById("calendar")
    // console.log("event.target", calendarDiv?.contains(event.target))
    if (calendarDiv?.contains(event.target)) {
      return;
    } else {
      if (openCalendar) {
        setOpenCalendar(false)
      }
    }
  }, [openCalendar])

  useEffect(() => {
    window.addEventListener('click', windowClick, false);
    return () => {
      window.removeEventListener('click', windowClick, false)
    }
  }, [windowClick])

  const firstUpdateImg = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdateImg.current) {
      firstUpdateImg.current = false;
      return;
    }

    setUplodaingExperiencLogoError(false);

    if (experienceModel.img1) {
      if (!logoDownloaded) {
        setLogoDownloaded(true);
        setUplodaingExperiencLogo(true);
        (async () => {
          const resourceResponse = await fetch(experienceModel.img1);
          setUplodaingExperiencLogo(false);

          if (resourceResponse) {
            if (resourceResponse.status === 200) {
              const blob = await resourceResponse.blob();
              const src = window.URL.createObjectURL(blob);
              setFileUrl(src);
            } else {
              setUplodaingExperiencLogoError(true);
            }
          }
        })();
      }
    }
  }, [
    experienceModel.img1,
    setUplodaingExperiencLogo,
    logoDownloaded
  ]);

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    // const startDateFromCalendar = dateRange[0].startDate?.getTime();
    // const durationInDays = Math.round((dateRange[0].endDate!.getTime() - dateRange[0].startDate!.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    // setDuration(durationInDays);

    // experienceModel.startdate = startDateFromCalendar;
    // experienceModel.duration = durationInDays;

    // saveExperienceToLocalStore();

    // console.log("dateRange", dateRange)


      if (user.stripeRole === 'basic' && user.email !== "guest@email.com") {
        if (firstUpdate.current) {
          firstUpdate.current = false;

          return;
        }
        const startDateFromCalendar = dateRange[0].startDate?.getTime();
        const durationInDays = Math.round((dateRange[0].endDate!.getTime() - dateRange[0].startDate!.getTime()) / (1000 * 60 * 60 * 24)) + 1;

        setDuration(durationInDays);

        experienceModel.startdate = startDateFromCalendar;
        experienceModel.duration = durationInDays;

        localStorage.setItem('dateChanged', 'true');
      saveExperienceToLocalStore(experienceModel);
      }
    

  }, [
    dateRange,
    experienceModel,
    saveExperienceToLocalStore,
    user.stripeRole,
    user.email
  ]);

  return (
    <div className='logoAndNameContainer'>
      <main>
        <h1>Personalize your experience!</h1>

        <p>
          You can personalize your experience by adding a logo, your brand
          color, the start date, and duration!
        </p>

        <div className='topContainer'>
          <div className='logoAndNameInputsContainer'>

            <div className='logoContainer'>
              <p>Logo</p>
              <div className='uploadLogo'>
                {experienceLogoLoading && (
                  <Spinner
                    style={{ float: 'left', marginTop: '10px', zIndex: 1000 }}
                    animation='border'
                    role='status'
                    size='sm'
                  >
                    <span className='sr-only'>Uploading</span>
                  </Spinner>
                )}

                <div className='uploadImageButton'>
                  {!experienceLogoLoading && !experienceModel.img1 && (
                    <svg
                      width='40'
                      height='40'
                      viewBox='0 0 30 29'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.92811 21.214C6.05054 19.3398 4.99545 16.7959 4.99545 14.143C4.99545 11.4901 6.05054 8.94609 7.92811 7.0719L8.06953 6.93048C10.9613 4.15438 15.2309 3.37228 18.9189 4.9431C22.6069 6.51391 25.0013 10.1344 25.0033 14.143C25.005 18.1894 22.5679 21.8381 18.8294 23.3862C15.0908 24.9344 10.7877 24.077 7.92811 21.214ZM14.0022 15.14V18.8028H15.9962V15.14H19.659V13.1459H15.9962L15.9962 9.48313H14.0022L14.0022 13.1459L10.3393 13.1459V15.14L14.0015 15.1407L14.0022 15.14Z'
                        fill={iconColor}
                      />
                    </svg>
                  )}

                  {experienceModel.img1 && !experienceLogoLoading && (
                    <>
                      <img
                        style={{ zIndex: 1000 }}
                        src={experienceModel.img1}
                        alt='logo'
                      />
                      <svg
                        className='swithLogo'
                        width='25'
                        height='25'
                        viewBox='0 0 22 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle cx='11' cy='11' r='9' fill='#F8F8F8' />
                        <path
                          d='M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM12.001 17.765C11.9241 17.8404 11.8268 17.8915 11.7211 17.9121C11.6155 17.9327 11.5061 17.9218 11.4065 17.8808C11.307 17.8399 11.2217 17.7705 11.1611 17.6815C11.1006 17.5925 11.0675 17.4876 11.066 17.38V16.5H11C9.592 16.5 8.184 15.961 7.106 14.894C6.35913 14.1453 5.84369 13.1974 5.62131 12.1635C5.39893 11.1296 5.47902 10.0536 5.852 9.064C6.061 8.503 6.798 8.36 7.216 8.789C7.458 9.031 7.513 9.383 7.403 9.691C6.897 11.055 7.183 12.639 8.283 13.739C9.053 14.509 10.065 14.872 11.077 14.85V13.816C11.077 13.321 11.671 13.079 12.012 13.431L13.794 15.213C14.014 15.433 14.014 15.774 13.794 15.994L12.001 17.765ZM14.784 13.222C14.6703 13.105 14.5923 12.958 14.5592 12.7982C14.5261 12.6385 14.5392 12.4726 14.597 12.32C15.103 10.956 14.817 9.372 13.717 8.272C12.947 7.502 11.935 7.128 10.934 7.15V8.184C10.934 8.679 10.34 8.921 9.999 8.569L8.206 6.798C7.986 6.578 7.986 6.237 8.206 6.017L9.988 4.235C10.0649 4.15963 10.1622 4.10847 10.2679 4.08788C10.3735 4.06729 10.4829 4.07816 10.5825 4.11915C10.682 4.16014 10.7673 4.22945 10.8279 4.31848C10.8884 4.40751 10.9215 4.51235 10.923 4.62V5.511C12.353 5.489 13.794 6.006 14.883 7.106C15.6299 7.85469 16.1453 8.80264 16.3677 9.83652C16.5901 10.8704 16.51 11.9464 16.137 12.936C15.928 13.508 15.202 13.651 14.784 13.222V13.222Z'
                          fill={iconColor}
                        />
                      </svg>
                    </>
                  )}

                  <LogoUploadButton
                    buttonId='logoupload'
                    buttonText=''
                    onFileSelected={onFileSelected}
                    fileTypes='.jpg, .jpeg, .png, .gif'
                  />
                </div>
              </div>
            </div>

            <div className='rightInputs'>
              <p>Color</p>
              <div className='colorButtonContainer'>
                <label
                  htmlFor='colorPickerInput'
                  className='colorPickerContainer'
                >
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 11 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.05882 1.4V0.7C9.05882 0.315 8.76765 0 8.41177 0H0.647059C0.291176 0 0 0.315 0 0.7V3.5C0 3.885 0.291176 4.2 0.647059 4.2H8.41177C8.76765 4.2 9.05882 3.885 9.05882 3.5V2.8H9.70588V5.6H3.23529V13.3C3.23529 13.685 3.52647 14 3.88235 14H5.17647C5.53235 14 5.82353 13.685 5.82353 13.3V7H11V1.4H9.05882Z'
                      fill={iconColor}
                    />
                  </svg>
                  <p>{brandColor}</p>
                </label>

                <input 
                  id='colorPickerTextInput'
                  type="text" 
                  value={brandColor} 
                  onChange={e => handleColorChange(e.target.value)} 
                />
                
                <input
                  id='colorPickerInput'
                  type='color'
                  value={brandColor}
                  onChange={e => handleColorChange(e.target.value)}
                />

              </div>
            </div>

            <div className='datePickerContainer'>
              {openCalendar && (
                <div id="calendar">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item: any) => { setDateRange([item.selection]); }}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    rangeColors={[iconColor]}
                    color="#F0386B"
                  />{' '}
                </div>
              )}

              <p>Duration</p>
              <div
                className={`datePickerButton ${user.email === 'guest@email.com' ||
                  user.email === '' ||
                  user.stripeRole === undefined
                  ? 'disabled'
                  : ''
                  }`}
                onClick={() => {
                  if (user.stripeRole === 'basic' && user.email !== "guest@email.com") {
                    setOpenCalendar(true)
                  } else {
                    setModalIsOpen(true)
                  }
                }}
              >
                <svg
                  style={{ marginBottom: '-8px' }}
                  width='25'
                  height='25'
                  viewBox='0 0 14 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.8889 8.8H7V12.8H10.8889V8.8ZM10.1111 0V1.6H3.88889V0H2.33333V1.6H1.55556C0.692222 1.6 0.00777777 2.32 0.00777777 3.2L0 14.4C0 15.28 0.692222 16 1.55556 16H12.4444C13.3 16 14 15.28 14 14.4V3.2C14 2.32 13.3 1.6 12.4444 1.6H11.6667V0H10.1111ZM12.4444 14.4H1.55556V5.6H12.4444V14.4Z'
                    fill={iconColor}
                  />
                </svg>

                <div className='dateInputContainer'>
                  <div className="textsContainer">
                    <p>from</p>
                    <span>{dateRange[0].startDate!.toLocaleDateString('undefind', { year: '2-digit', month: 'numeric', day: 'numeric' })}</span>
                    <p>Duration</p>
                    <span>{duration}{duration < 2 ? ' day' : ' days'}</span>
                  </div>
                </div>


                {user.stripeRole !== "basic" && (
                  <svg
                    className='premiumIcon'
                    width='25'
                    height='25'
                    viewBox='0 0 16 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16 7.49642L14.2255 5.50406L14.4727 2.8667L11.8473 2.27903L10.4727 0L8 1.04634L5.52727 0L4.15273 2.27903L1.52727 2.85953L1.77455 5.49689L0 7.49642L1.77455 9.48877L1.52727 12.1333L4.15273 12.721L5.52727 15L8 13.9465L10.4727 14.9928L11.8473 12.7138L14.4727 12.1261L14.2255 9.48877L16 7.49642ZM8.72727 11.0798H7.27273V9.64644H8.72727V11.0798ZM8.72727 8.21309H7.27273V3.91304H8.72727V8.21309Z'
                      fill='#BBA934'
                    />
                  </svg>
                )}

              </div>
            </div>
          </div>
        </div>

        <button
          className='createExpNextButton'
          onClick={() => setCreateExperienceStep('publish')}
        >
          SAVE
        </button>


        {user.stripeRole !== "basic" && (
          <div className='personalizeFooter' onClick={() => setCreateExperienceStep('subscription')}>
            <svg
              width='25'
              height='25'
              viewBox='0 0 16 15'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16 7.49642L14.2255 5.50406L14.4727 2.8667L11.8473 2.27903L10.4727 0L8 1.04634L5.52727 0L4.15273 2.27903L1.52727 2.85953L1.77455 5.49689L0 7.49642L1.77455 9.48877L1.52727 12.1333L4.15273 12.721L5.52727 15L8 13.9465L10.4727 14.9928L11.8473 12.7138L14.4727 12.1261L14.2255 9.48877L16 7.49642ZM8.72727 11.0798H7.27273V9.64644H8.72727V11.0798ZM8.72727 8.21309H7.27273V3.91304H8.72727V8.21309Z'
                fill='#BBA934'
              />
            </svg>
            <p>
              Premium feature, <strong>Subscribe now to try!</strong>
            </p>
          </div>
        )}

      </main>

      {/* // TODO: What does this do? */}
      <div style={{ display: 'none' }}>
        {startDate.toJSON()}
        {uplodaingExperiencLogo.valueOf()}
        {uplodaingExperiencLogoError.valueOf()}
        {uploadExperience.toString()}
        {logoFileUrl}
      </div>
    </div>
  );
};
