import ExperienceModel from 'src/models/ExperienceModel';
import UploadImageFileModel from 'src/models/UploadImageFileModel';
import UploadVideoFileModel from 'src/models/UploadVideoFileModel';

export const checkForUploadingFiles = (experience: ExperienceModel) => {
  const response = imageFilesStillUploading(experience);

  const videosResponse = videoFilesStillUploading(experience);

  if (!videosResponse.success) {
    response.success = videosResponse.success;

    videosResponse.messages.map((message: string) => {
      response.messages.push(message);
      return true;
    });
  }

  return response;
};

export const imageFilesStillUploading = (experience: ExperienceModel) => {
  const response = { success: true, messages: new Array<string>() };

  experience.trackables.map(function (trackable: UploadImageFileModel) {
    if (trackable.fileUploadError) {
      response.success = false;
      response.messages.push(
        'There was a problem uploading the illstration ' + trackable.name
      );
    }

    if (trackable.stillUploading) {
      response.success = false;
      response.messages.push(
        'Illustration ' + trackable.name + ' is still uploading'
      );
    }

    return true;
  });

  return response;
};

export const videoFilesStillUploading = (experience: ExperienceModel) => {
  const response = { success: true, messages: new Array<string>() };

  experience.videos.map(function (video: UploadVideoFileModel) {
    if (video.uploadedError) {
      response.success = false;
      response.messages.push(
        'There was a problem uploading the video  ' + video.name
      );
    }

    if (video.stillUploading) {
      response.success = false;
      response.messages.push('Video ' + video.name + ' is still uploading');
    }

    return true;
  });

  return response;
};
