import React, { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { iconColor } from 'src/config';
import { ExperienceContext } from 'src/contexts/ExperienceContext';
import UserModel from 'src/models/UserModel';
import { subscribe } from 'src/services/checkoutService';
import { Product } from 'src/services/firebase';
// import Success from '../success';

import './SubscriptionProductCard.css';

interface IProps {
  product: Product;
  user: UserModel;
}

const SubscriptionProductCard: React.FC<IProps> = ({ product, user }) => {
  const {
    experience,
    // saveExperience,
    saveExperienceToLocalStore,
    setCreateExperienceStep,
    setFreePlan
  } = useContext(ExperienceContext);
  const [isLoading, setIsLoading] = useState(false);
  // const [success, setSuccess] = useState<boolean>();

  const priceId = Object.keys(product.prices)[0];

  function handleClick(product: Product) {
    setIsLoading(true);

    // Free
    if (product.name === 'Free') {
      experience.duration = 1;
      setFreePlan(true);

      // saveExperience(experience);
      // setSuccess(true);
      setCreateExperienceStep('success');
      // window.location.href = '/create-experience/success'
      return;
    }

    // One time
    if (product.name === 'One time') {
      experience.duration = 14;
      saveExperienceToLocalStore(experience);
      if (user.email === 'guest@email.com') {
        setCreateExperienceStep('loggin');
      } else {
        subscribe(product, priceId, user.id, experience.title);
      }
    } else {
      // All other cases
      saveExperienceToLocalStore(experience);
      if (user.email === 'guest@email.com') {
        setCreateExperienceStep('loggin');
      } else {
        subscribe(product, priceId, user.id, experience.title);
      }
    }
  }

  const pricesObj = product.prices[priceId];
  const finalPriceToDisplay = pricesObj.unit_amount / 100;

  // if (success) {
  //   return (
  //     <Success
  //       user={user}
  //       setUserModels={() => {
  //         /*noop */
  //       }}
  //       freePlan={true}
  //     ></Success>
  //   );
  // }

  return (
    <div>
      <div className='productContainer'>
        <h2>{product.name}</h2>
        <span></span>

        <ul>
          {JSON.parse(product.metadata.features).map(
            (feature: string, index: number) => (
              <li className='productItem' key={index}>
                <svg
                  width='19'
                  height='17'
                  viewBox='0 0 9 7'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M3.47482 4.63251L0.935526 2.38492L0 3.23533L3.47482 6.33333L8.68651 0.866281L7.75098 0.0158691L3.47482 4.63251Z'
                    fill={iconColor}
                  />
                </svg>
                <p>{feature}</p>
              </li>
            )
          )}
        </ul>

        <button onClick={() => handleClick(product)}>
          {isLoading ? (
            <Spinner animation='border' role='status' size='sm'>
              <span className='sr-only'>Uploading</span>
            </Spinner>
          ) : (
            <p>SELECT</p>
          )}
        </button>

        <div className='priceTage'>
          <svg
            width='85'
            height='57'
            viewBox='0 0 85 57'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0 0.631592H85V35.0364H0L6.07143 17.834L0 0.631592Z'
              fill={iconColor}
            />
            <path
              d='M60.207 56.2864L84.4927 35.0364H60.207V56.2864Z'
              fill='#1A1A1B'
            />
          </svg>

          {finalPriceToDisplay === 0 && (
            <h4 className='priceTagNumber'>FREE</h4>
          )}

          {finalPriceToDisplay !== 0 && pricesObj.type === 'one_time' && (
            <h4 className='priceTagNumber'>{`$ ${finalPriceToDisplay}`}</h4>
          )}

          {finalPriceToDisplay !== 0 && pricesObj.type !== 'one_time' && (
            <h4 className='priceTagNumber month'>
              {`$ ${finalPriceToDisplay}`}
              <span>/mo</span>
            </h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionProductCard;
