import React, { useState, useEffect } from "react";
import UploadImageFileModel from "src/models/UploadImageFileModel";
import './drop-down-component.css';

interface IProps {
    illustrations: Array<UploadImageFileModel>;
    selectedOptionId: string;
    setSelectedOption: (selectionOptionId: string) => void;
}


const DropDownComponent: React.FC<IProps> = ({ illustrations, setSelectedOption, selectedOptionId }) => {
    const [showTargetImageOptions, setShowTargetImageOptions] = useState(false);
    const [selectedTargetImage, setSelectedTargetImage] = useState<UploadImageFileModel>({
        id: "",
        name: "",
        url: "",
        video: 0,
        videoId: "",
        fileUploadedSuccessfully: false,
        fileUploadError: false,
        stillUploading: false,
        selectedFile: "",
        selectedFilDisplay: "",
        popUpFile: ""
    })


    function handleOptionSelectChange(e: any) {
        setShowTargetImageOptions(false)

        const selectedOptionId = e;
        setSelectedOption(selectedOptionId);


        const selectedTargetImage = illustrations.find(o => o.id === e);
        if (selectedTargetImage) {
            setSelectedTargetImage(selectedTargetImage)
        }
    }


    useEffect(() => {
        const selectedTargetImage = illustrations.find(o => o.id === selectedOptionId);

        if (selectedOptionId !== "") {
            const selectedTargetImage = illustrations.find(o => o.id === selectedOptionId);
            if (selectedTargetImage) {
                setSelectedTargetImage(selectedTargetImage);
                setSelectedOption(selectedTargetImage.id)
            }
        }

        if (selectedTargetImage === undefined || (selectedOptionId === "" && illustrations.length === 1)) {
            setSelectedTargetImage(illustrations[0]);
            setSelectedOption(illustrations[0].id)
        }
    }, [illustrations, selectedOptionId, setSelectedOption])


    return (
        <>
            {selectedTargetImage.id !== "" && (
                <aside onClick={() => setShowTargetImageOptions(!showTargetImageOptions)}>
                    {selectedTargetImage.url !== "" ? (
                        <img src={selectedTargetImage && `${selectedTargetImage.url}.jpeg`} alt="Target" />
                    ) : (
                        <img src={selectedTargetImage && selectedTargetImage.popUpFile} alt="Target" />
                    )}
                    
                    <h4>{selectedTargetImage && selectedTargetImage.name}</h4><span> - Target-Image</span>
                </aside>
            )}


            {selectedOptionId === "" && illustrations.length > 1 ? (
                <aside onClick={() => setShowTargetImageOptions(!showTargetImageOptions)}>
                    <div className="selectTargetImageSvgContainer">
                        <svg className="selectTargetImageSvg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0H16C17.1046 0 18 0.89543 18 2V16C18 17.1046 17.1046 18 16 18ZM2 2V16H16V2H2ZM15 14H3L6 10L7 11L10 7L15 14ZM5.5 8C4.67157 8 4 7.32843 4 6.5C4 5.67157 4.67157 5 5.5 5C6.32843 5 7 5.67157 7 6.5C7 7.32843 6.32843 8 5.5 8Z" fill="#A5A5B0" />
                        </svg>
                    </div>

                    <h4>Select Target-Image</h4>
                </aside>
            ) : (null)}

            {illustrations.length > 1 && (
                <>
                    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-3.32859e-07 8.34465e-07L7.5 9L15 5.95337e-08L-3.32859e-07 8.34465e-07Z" fill="#E1E1E6" />
                    </svg>

                    <div className="imageTargetsOptions">
                        {showTargetImageOptions && (
                            <>
                                {illustrations.map(el =>
                                    <div className="imageTargetItem" key={el.id} onClick={() => handleOptionSelectChange(el.id)}>
                                        <img src={el.url === "" ? el.popUpFile : `${el.url}.jpeg`} alt="Target Option" />
                                        <p>{el.name}</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    )
}

export default DropDownComponent;