import isDevelopment from './utilities';
import { v4 as uuidv4 } from 'uuid';
import { StorageService } from 'src/services/StorageService';
import { Constants } from 'src/models/Constants';
import ExperienceModel from 'src/models/ExperienceModel';

export class ExperienceFileNameService {
  public static BuildNewExperienceFilename(
    uuid: string,
    experienceName: string,
    userEmail: string,
    originalExperienceName: string
  ) {
    const result = { filePath: '', success: false, message: '' };

    const valildateName = this.ValidateExperienceName(
      experienceName,
      originalExperienceName
    );

    if (valildateName.success) {
      // This will be needed when saving the experience json by experience name
      // const newFileName = uuid.replace(/\s+/g, '_');

      // Build folder structure based on user email address
      const filePath = 'exp/' + userEmail + '/' + uuid;

      result.success = true;
      result.filePath = filePath;
    } else {
      result.message = valildateName.message;
    }

    return result;
  }

  public static BuildNewLogoFilename(fileType: string, userEmail: string) {
    const result = { filePath: '', success: false, message: '' };

    const indexOf = fileType.indexOf('/');

    let fileSuffix = 'unkown';
    if (indexOf > 0) {
      fileSuffix = fileType.substr(indexOf + 1);
    }

    const id = uuidv4();
    const logoFileName = 'companyLogo' + id;
    let fileNameUnique = '';

    fileNameUnique = logoFileName + '.' + fileSuffix;

    if (isDevelopment()) {
      fileNameUnique = 'development-' + logoFileName + '.' + fileSuffix;
    }

    // Build folder structure based on user email address
    const filePath = 'exp/' + userEmail + '/' + fileNameUnique;

    result.success = true;
    result.filePath = filePath;

    return result;
  }

  public static ValidateExperienceName(
    experienceName: string,
    originalExperienceName: string
  ) {
    const result = { success: false, message: '' };

    if (experienceName && experienceName.length > 2) {
      const exists = ExperienceFileNameService.doesExperienceNameExist(
        experienceName,
        originalExperienceName
      );

      if (exists) {
        result.message =
          'Experience name is already in use - please enter a different name';
        return result;
      }

      const alphanumericCharacterSet = /^[0-9a-zA-Z_-]+$/;
      const noSpacesName = experienceName.replace(/\s/g, '');
      if (noSpacesName.match(alphanumericCharacterSet)) {
        result.success = true;
      } else {
        result.message = 'Experience name must be letters and numbers only';
      }
    } else {
      result.message = "Experience name must be at least 3 character's";
    }

    return result;
  }

  private static doesExperienceNameExist(
    experienceName: string,
    originalExperienceName: string
  ) {
    const newFileName = experienceName.replace(/\s+/g, '_');
    const existingExperiences = StorageService.Get(Constants.ExperienceListKey);

    if (existingExperiences) {
      if (
        originalExperienceName.length > 0 &&
        experienceName === originalExperienceName
      ) {
        // The user is editing an existing experience and they haven't modified the
        // experience name.
        return false;
      } else {
        // The user is saving a new experience or they've modified an existing experience name
        const exp = JSON.parse(existingExperiences) as ExperienceModel[];
        const nameMatchFound = exp.find((exp) => exp.title === newFileName);

        if (nameMatchFound) {
          return true;
        }
      }
    }

    return false;
  }

  private static validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  public static ValidateUserEmail(emilAddress: string) {
    const result = { success: false, message: '' };
    const errorMessage =
      'Sorry - your user login must include a valid email address. Please contact support.';

    if (emilAddress && emilAddress.length > 0) {
      if (ExperienceFileNameService.validateEmail(emilAddress)) {
        result.success = true;
      } else {
        result.message = errorMessage;
      }
    } else {
      result.message = errorMessage;
    }

    return result;
  }
}
