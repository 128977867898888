export class StorageService {
  private localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
  }

  public static Get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public static Store(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public static Clear(key: string) {
    localStorage.setItem(key, '');
  }
}
