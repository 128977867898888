import React, { useContext, useEffect, useState } from 'react'
import SubscriptionProductCard from '../subscription-product-card';

import { Product } from '../../services/firebase';

import './SubscriptionPage.css';
import UserModel from 'src/models/UserModel';
import { ExperienceContext } from 'src/contexts/ExperienceContext';
import { Spinner } from 'react-bootstrap';
// import { set } from 'date-fns';

interface IProps {
    user: UserModel;
    products: Product[] | undefined;
}

const SubscriptionPage: React.FC<IProps> = ({ user, products }) => {
    const { setCreateExperienceStep } = useContext(ExperienceContext);

    const [ displayProducts, setDisplayProducts ] = useState<Product[]>();

    useEffect(() => {
        let development = process.env.NODE_ENV !== 'production';
        const storageProducts = localStorage.getItem('products');

        if(storageProducts !== null && storageProducts !== "") {
            const productsObj = JSON.parse(storageProducts);
            setDisplayProducts(productsObj);
        } else {
            if(!development && products) {
                const developmentProducts = products.filter(product => product.description !== "TEST PRIME");
                setDisplayProducts(developmentProducts);
            }
    
            if(user.stripeRole === "basic" && user.email !== "guest@email.com" && products) {
                const filteredProducts = products.filter(product => product.name === "Free" || product.name === "One time")
                setDisplayProducts(filteredProducts);
            }
        }
    }, [
        products,
        user.stripeRole,
        user.email
    ])

    return (
        <div className="subscriptionPageContainer">

            <h1><strong>Excellent! </strong> Now, choose a plan that suits you best:</h1>

            <main>
                {!displayProducts || displayProducts.length === 0 ? (
                    <Spinner className="spinnerCircle" animation="border" role="status">
                        <span className="sr-only">Getting products</span>
                    </Spinner>
                ) : null}

                {displayProducts && displayProducts.map((product, index) =>
                    <SubscriptionProductCard key={index} product={product} user={user} />
                )}
            </main>

            <button onClick={() => setCreateExperienceStep('publish')}>BACK</button>

            <h2>Why subscribe?</h2>
            <p>
                Get access to enhanced features such as: adding multiple target images, upload longer videos, extend the duration your experience is live, and much more!
            </p>

        </div>
    )
}


export default SubscriptionPage;