import React, { Component } from 'react';
import UploadVideoFileModel from '../../models/UploadVideoFileModel';
import UploadImageFileModel from 'src/models/UploadImageFileModel';
import { v4 as uuidv4 } from 'uuid';
import { iconColor } from 'src/config';
import UserModel from 'src/models/UserModel';
import './styles/upload-video.css'

const CONST_IMG_WIDTH = 3100;

interface IProps {
  addVideo: (video: UploadVideoFileModel) => void;
  illustrations: Array<UploadImageFileModel>;
  setAddingMoreFilters: (adding: boolean) => void;
  setUploadListOfFilters: (adding: boolean) => void;
  user: UserModel;
  canActivateSound: boolean;
}

interface IState {
  filterType: "Video" | "360° Image" | "360° Video" | "" | "3D Object";
  videoFile: File | null;
  videoFileDisplay: string | null;
  videoUrl: string;
  videoName: string;
  message: string;
  messageColor: string;
  validDuration: boolean;
  is360: boolean;
  rotationDegrees: number | null;
  muteSound: boolean;
  staysVisible: boolean;
  loops: boolean;
  transparentColor: boolean;
  transparentColorCode: string;
  preview: boolean;
}

class VideoUpload extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      filterType: "",
      videoFile: null,
      videoFileDisplay: null,
      videoUrl: "",
      videoName: "",
      messageColor: "#FFEE88",
      message: "",
      validDuration: true,
      is360: false,
      rotationDegrees: null,
      muteSound: false,
      staysVisible: false,
      loops: false,
      transparentColor: false,
      transparentColorCode: "#00FF00",
      preview: false
    };

    this.resetState = this.resetState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleVideoLoad = this.handleVideoLoad.bind(this);
    this.onVideoFileSelected = this.onVideoFileSelected.bind(this);
    this.addVideoHandler = this.addVideoHandler.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.handleVideoNameChange = this.handleVideoNameChange.bind(this);
    this.handleIs360Change = this.handleIs360Change.bind(this);
    this.handleMuteSound = this.handleMuteSound.bind(this);
    this.handleStaysVisible = this.handleStaysVisible.bind(this);
    this.handleLoopsChange = this.handleLoopsChange.bind(this);
    this.handleRotationDegreesChange = this.handleRotationDegreesChange.bind(this);

  }

  onVideoFileSelected(file: File | null) {
    this.setState({ videoFile: file });

    if (!file) return;
    const videoFileName = file.name
    if (this.state.videoName.length === 0) {
      this.setState({videoName: videoFileName.split('.').slice(0, -1).join('.')})
    }

    if (file !== null && file !== undefined) {
      const url = window.URL.createObjectURL(file);
      this.setState({ videoFileDisplay: url });
    } else {
      this.setState({ videoFileDisplay: "" });
    }

  }

  handleVideoLoad(e: any) {
    const duration = e.target.duration;

    if(!this.props.canActivateSound) {
      this.setState({ muteSound: true });
    }

    if(this.props.user.stripeRole === "basic" && this.props.user.email !== "guest@email.com") {
      if(duration < 180) {
        this.setState({ validDuration: true });
      } else {
        this.setState({ validDuration: false });
      }
    } else {
      if (duration > 60) {
        this.setState({ validDuration: false });
      } else {
        this.setState({ validDuration: true });
      }
    }
  }

  handleImageLoad = (e: any) => {
    if (this.state.filterType === "360° Image") {
      const imgWidth = e.target.naturalWidth;
      const imgHeight = e.target.naturalHeight;

      if (imgWidth > CONST_IMG_WIDTH || imgHeight > CONST_IMG_WIDTH) {
        this.setState({message: `Your image dimensions are too big. Please resize to ${CONST_IMG_WIDTH}px max in either dimension.`})
      }
    }
  }

  handleVideoNameChange(e: any) {
    e.preventDefault();
    this.clearMessage();

    const newName = e.target.value;
    this.setState({ videoName: newName })
  }

  handleIs360Change() {
    const is360NewValue = !this.state.is360;
    this.setState({ is360: is360NewValue });
  }

  handleMuteSound() {
    const muteSoundNewValue = !this.state.muteSound;
    this.setState({ muteSound: muteSoundNewValue });
  }

  handleStaysVisible() {
    const staysVisibleNewValue = !this.state.staysVisible;
    this.setState({ staysVisible: staysVisibleNewValue });
  }

  handleLoopsChange() {
    const loops = !this.state.loops;
    this.setState({ loops: loops });
  }

  handleRotationDegreesChange(e: any) {
    e.preventDefault();
    this.clearMessage();

    const degrees = e.target.value;
    this.setState({ rotationDegrees: degrees })
  }

  clearMessage() {
    this.setState({ message: "" });
    this.setState({ messageColor: "#FFEE88" });
  }

  handleChange(event: any) {
    this.setState({ message: "" })
    this.onVideoFileSelected(event.target.files[0]);
  }

  resetState() {
      this.setState({
        filterType: "",
        videoFile: null,
        videoFileDisplay: null,
        videoUrl: "",
        videoName: "",
        messageColor: "FFEE88",
        message: "",
        validDuration: true,
        is360: false,
        rotationDegrees: null,
        muteSound: false,
        staysVisible: false,
        loops: false,
        transparentColor: false,
        transparentColorCode: "#00ff00",
        preview: false,
      })
      this.onVideoFileSelected(null);
      
      if(!this.props.canActivateSound) {
        this.setState({ muteSound: true });
      }
  }

  onFilterTypeChange() {
    this.setState({
      loops: false,
      staysVisible: false,
      muteSound: false,
      transparentColor: false,
      rotationDegrees: null,
      preview: false
    });
  }

  addVideoHandler() {
    if (this.state.videoFile === null) {
      this.setState({ message: "Please select a video." });
      this.setState({ messageColor: "#FFEE88" });
      return;
    }

    this.props.setUploadListOfFilters(false);
    const videoId = uuidv4();

    const model = new UploadVideoFileModel();
    model.id = videoId;

    if (this.state.videoName.length === 0) {
      model.videoFileName = "Unnamed Filter";
    } else {
      model.videoFileName = this.state.videoName;
    }

    model.videoFile = this.state.videoFile;
    model.videoFileDisplay = this.state.videoFileDisplay;
    model.three60 = this.state.is360;
    model.staysVisible = this.state.staysVisible;
    model.loops = this.state.loops;
    model.filterType = this.state.filterType;
    model.transparentColor = this.state.transparentColor;
    model.transparentColorCode = this.state.transparentColorCode;   
    model.muted = this.state.muteSound;

    if(!this.props.canActivateSound) {
      model.muted = true;
    }


    if(this.state.rotationDegrees === null) {
      model.rotation = 0;
    } else {
      model.rotation = this.state.rotationDegrees;
    }

    window.scrollTo(0, 0);

    this.props.addVideo(model);
    this.props.setAddingMoreFilters(false);
    this.clearMessage();
    this.resetState();
  }

  handleAddRotationInDegrees(e: any) {
    const regex = new RegExp(/^-?\d*\.?\d*$/);

    if (regex.test(e.target.value)) {
      this.setState({ rotationDegrees: e.target.value })
    }
  }

  render() {
    return (
      <>
        <div className="uploadFilterMainContainer">
          {this.state.message.length > 0 && (
            <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '20px', color: 'red' }}>
              <div style={{ backgroundColor: "inheret", color: this.state.messageColor }}>
                {this.state.message}
              </div>
            </div>
          )}

          {(!this.state.validDuration &&
            this.state.filterType.includes("Video")) ? (
            <>
              {this.props.user.stripeRole === "basic" && this.props.user.email !== "guest@email.com" ? (
                <p className="videoTooLong">
                  Your video message is too long. Please upload a video that is no more then 3 minutes long.
                </p>
              ) : (
                <p className="videoTooLong">
                  Your video message is too long. Please upload a video that is no more then 1 minute long.
                </p>
              )}

              {this.state.message.length > 0 ? (
                <div style={{ backgroundColor: "inheret", color: this.state.messageColor, marginTop: '10px' }}>
                  {this.state.message}
                </div>
              ) : (null)}
            </>
          ) : (null)}

          {!this.state.videoFileDisplay && (
            <>
              <p>Upload filter by type</p>
              <div className="videoFormatContainer">


                <div className={`formatItem ${this.state.filterType === 'Video' ? "active" : ""}`} onClick={() => { this.setState({ filterType: 'Video' }); this.onFilterTypeChange() }}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3915 19.7673C5.1192 19.7616 0.84661 15.4894 0.84082 10.2176V10.0266C0.945812 4.77863 5.26716 0.599125 10.5163 0.668765C15.7654 0.738406 19.9743 5.03108 19.9401 10.28C19.9058 15.529 15.6411 19.7663 10.3915 19.7673ZM8.48136 5.92027V14.515L14.2118 10.2176L8.48136 5.92027Z" fill={this.state.filterType === "Video" ? "#fff" : iconColor} />
                  </svg>

                  <label htmlFor={`video`} style={{ width: '100%', height: '100%', position: 'absolute' }}></label>
                  <input id={`video`} type="file" accept="video/*" style={{ display: "none", width: "100%" }} onChange={this.handleChange} />
                  <p>Video</p>
                </div>

                {/* <div className={`formatItem ${this.state.filterType === '360° Video' ? "active" : ""}`} onClick={() => { this.setState({ is360: true, filterType: '360° Video' }); this.onFilterTypeChange() }}>
                  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.205 13.3977C16.5144 13.3937 13.5236 10.4032 13.5195 6.71295V6.57925C13.593 2.90564 16.618 -0.0200105 20.2924 0.0287376C23.9668 0.0774858 26.913 3.08236 26.889 6.75662C26.865 10.4309 23.8797 13.397 20.205 13.3977ZM18.8679 3.70479V9.72111L22.8792 6.71295L18.8679 3.70479Z" fill={this.state.filterType === "360° Video" ? "#fff" : iconColor} />
                    <path d="M6.83496 18.1726C6.83496 19.7579 8.11462 21.0375 9.69988 21.0375C11.2851 21.0375 12.5648 19.7579 12.5648 18.1726C12.5648 16.5874 11.2851 15.3077 9.69988 15.3077C8.11462 15.3077 6.83496 16.5874 6.83496 18.1726Z" fill={this.state.filterType === "360° Video" ? "#fff" : iconColor} />
                    <path d="M5.88022 16.2627V14.3528H3.10125C4.41912 12.0704 6.88294 10.5329 9.70011 10.5329C13.2526 10.5329 16.2417 12.9776 17.0916 16.2627H19.0588C18.1707 11.908 14.3222 8.62292 9.70011 8.62292C6.57735 8.62292 3.79838 10.1318 2.06033 12.4524V10.5329H0.150391V16.2627H5.88022Z" fill={this.state.filterType === "360° Video" ? "#fff" : iconColor} />
                    <path d="M13.5185 20.0826V21.9925H16.2974C14.9796 24.2749 12.5157 25.8124 9.69857 25.8124C6.14607 25.8124 3.15701 23.3677 2.30709 20.0826H0.339844C1.22797 24.4373 5.0765 27.7224 9.69857 27.7224C12.8213 27.7224 15.6003 26.2135 17.3383 23.8929V25.8124H19.2483V20.0826H13.5185Z" fill={this.state.filterType === "360° Video" ? "#fff" : iconColor} />
                  </svg>

                  <label htmlFor={`360video`} style={{ width: '100%', height: '100%', position: 'absolute' }}></label>
                  <input id={`360video`} type="file" accept="video/*" style={{ display: "none", width: "100%" }} onChange={this.handleChange} />
                  <p>360° Video</p>
                </div> */}


                {/* <div className={`formatItem ${this.state.filterType === 'Image' ? "active" : ""}`} onClick={() => {this.setState({ filterType: 'Image'}); this.onFilterTypeChange()} }>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.321 19.7673H2.46591C1.29387 19.7673 0.34375 18.8172 0.34375 17.6452V2.79007C0.34375 1.61803 1.29387 0.667908 2.46591 0.667908H17.321C18.4931 0.667908 19.4432 1.61803 19.4432 2.79007V17.6452C19.4432 18.8172 18.4931 19.7673 17.321 19.7673ZM2.46591 2.79007V17.6452H17.321V2.79007H2.46591ZM16.2599 15.523H3.52699L6.71023 11.2787L7.77131 12.3398L10.9545 8.09547L16.2599 15.523ZM6.17969 9.15655C5.30066 9.15655 4.58807 8.44395 4.58807 7.56493C4.58807 6.6859 5.30066 5.97331 6.17969 5.97331C7.05872 5.97331 7.77131 6.6859 7.77131 7.56493C7.77131 8.44395 7.05872 9.15655 6.17969 9.15655Z" fill={this.state.filterType === "Image" ? "#fff" : iconColor} />
                    </svg>

                    <label htmlFor={`image`} style={{ width: '100%', height: '100%', position: 'absolute' }}></label>
                    <input id={`image`} type="file" accept=".png, .jpg, .jpeg"  style={{ display: "none", width: "100%" }} onChange={this.handleChange} />
                    <p>Image</p>
                </div> */}

                {/* <div className={`formatItem ${this.state.filterType === '360° Image' ? "active" : ""}`} onClick={() => { this.setState({ is360: true, filterType: '360° Image' }); this.onFilterTypeChange() }}>
                  <svg width="30" height="28" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27.3372 13.7392H16.9386C16.1182 13.7392 15.4531 13.0741 15.4531 12.2537V1.85514C15.4531 1.03472 16.1182 0.369629 16.9386 0.369629H27.3372C28.1576 0.369629 28.8227 1.03472 28.8227 1.85514V12.2537C28.8227 13.0741 28.1576 13.7392 27.3372 13.7392ZM16.9386 1.85514V12.2537H27.3372V1.85514H16.9386ZM26.5945 10.7682H17.6814L19.9097 7.79719L20.6524 8.53994L22.8807 5.56892L26.5945 10.7682ZM19.5383 6.31168C18.923 6.31168 18.4241 5.81286 18.4241 5.19754C18.4241 4.58222 18.923 4.08341 19.5383 4.08341C20.1536 4.08341 20.6524 4.58222 20.6524 5.19754C20.6524 5.81286 20.1536 6.31168 19.5383 6.31168Z" fill={this.state.filterType === "360° Image" ? "#fff" : iconColor} />
                    <path d="M6.8584 18.5141C6.8584 20.0993 8.13806 21.379 9.72331 21.379C11.3086 21.379 12.5882 20.0993 12.5882 18.5141C12.5882 16.9288 11.3086 15.6492 9.72331 15.6492C8.13806 15.6492 6.8584 16.9288 6.8584 18.5141Z" fill={this.state.filterType === "360° Image" ? "#fff" : iconColor} />
                    <path d="M5.90268 16.6041V14.6942H3.12371C4.44158 12.4118 6.9054 10.8743 9.72257 10.8743C13.2751 10.8743 16.2641 13.319 17.1141 16.6041H19.0813C18.1932 12.2495 14.3446 8.96436 9.72257 8.96436C6.59981 8.96436 3.82084 10.4732 2.0828 12.7938V10.8743H0.172852V16.6041H5.90268Z" fill={this.state.filterType === "360° Image" ? "#fff" : iconColor} />
                    <path d="M13.5419 20.4241V22.334H16.3209C15.003 24.6164 12.5392 26.1539 9.72201 26.1539C6.16951 26.1539 3.18045 23.7092 2.33052 20.4241H0.363281C1.25141 24.7787 5.09994 28.0638 9.72201 28.0638C12.8448 28.0638 15.6237 26.555 17.3618 24.2344V26.1539H19.2717V20.4241H13.5419Z" fill={this.state.filterType === "360° Image" ? "#fff" : iconColor} />
                  </svg>


                  <label htmlFor={`360image`} style={{ width: '100%', height: '100%', position: 'absolute' }}></label>
                  <input id={`360image`} type="file" accept=".png, .jpg, .jpeg" style={{ display: "none", width: "100%" }} onChange={this.handleChange} />
                  <p>360° Image</p>
                </div> */}


                {/* <div className={`formatItem ${this.state.filterType === '3D Object' ? "active" : ""}`} onClick={() => {this.setState({ filterType: '3D Object'}); this.onFilterTypeChange()}}> */}
                {/* <div className={`formatItem ${this.state.filterType === '3D Object' ? "active" : ""}`}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5918 7.06149L12.9918 3.24549C12.4398 2.92149 11.7438 2.92149 11.1918 3.24549L4.5918 7.06149C4.0398 7.38549 3.6918 7.97349 3.6918 8.62149V16.2415C3.6918 16.8895 4.0398 17.4775 4.5918 17.8015L11.1918 21.6175C11.7438 21.9415 12.4398 21.9415 12.9918 21.6175L19.5918 17.8015C20.1438 17.4775 20.4918 16.8895 20.4918 16.2415V8.62149C20.4918 7.97349 20.1438 7.38549 19.5918 7.06149ZM6.0918 15.8935V10.3495L10.8918 13.1335V18.6655L6.0918 15.8935ZM12.0918 11.0575L7.2918 8.27349L12.0918 5.50149L16.8918 8.27349L12.0918 11.0575ZM13.2918 18.6655V13.1335L18.0918 10.3495V15.8935L13.2918 18.6655ZM6.0918 0.341492H1.8918C0.895797 0.341492 0.0917969 1.14549 0.0917969 2.14149V6.34149H2.4918V2.74149H6.0918V0.341492ZM18.0918 0.341492H22.2918C23.2878 0.341492 24.0918 1.14549 24.0918 2.14149V6.34149H21.6918V2.74149H18.0918V0.341492ZM6.0918 24.3415H1.8918C0.895797 24.3415 0.0917969 23.5375 0.0917969 22.5415V18.3415H2.4918V21.9415H6.0918V24.3415ZM18.0918 24.3415H22.2918C23.2878 24.3415 24.0918 23.5375 24.0918 22.5415V18.3415H21.6918V21.9415H18.0918V24.3415Z" fill={this.state.filterType === "3D Object" ? "#fff" : iconColor} />
                    <path d="M19.5918 7.06149L12.9918 3.24549C12.4398 2.92149 11.7438 2.92149 11.1918 3.24549L4.5918 7.06149C4.0398 7.38549 3.6918 7.97349 3.6918 8.62149V16.2415C3.6918 16.8895 4.0398 17.4775 4.5918 17.8015L11.1918 21.6175C11.7438 21.9415 12.4398 21.9415 12.9918 21.6175L19.5918 17.8015C20.1438 17.4775 20.4918 16.8895 20.4918 16.2415V8.62149C20.4918 7.97349 20.1438 7.38549 19.5918 7.06149ZM6.0918 15.8935V10.3495L10.8918 13.1335V18.6655L6.0918 15.8935ZM12.0918 11.0575L7.2918 8.27349L12.0918 5.50149L16.8918 8.27349L12.0918 11.0575ZM13.2918 18.6655V13.1335L18.0918 10.3495V15.8935L13.2918 18.6655ZM6.0918 0.341492H1.8918C0.895797 0.341492 0.0917969 1.14549 0.0917969 2.14149V6.34149H2.4918V2.74149H6.0918V0.341492ZM18.0918 0.341492H22.2918C23.2878 0.341492 24.0918 1.14549 24.0918 2.14149V6.34149H21.6918V2.74149H18.0918V0.341492ZM6.0918 24.3415H1.8918C0.895797 24.3415 0.0917969 23.5375 0.0917969 22.5415V18.3415H2.4918V21.9415H6.0918V24.3415ZM18.0918 24.3415H22.2918C23.2878 24.3415 24.0918 23.5375 24.0918 22.5415V18.3415H21.6918V21.9415H18.0918V24.3415Z" fill={"#202024"} />
                  </svg>


                  <label htmlFor={`3dobject`} style={{ width: '100%', height: '100%', position: 'absolute' }}></label>
                  <input id={`3dobject`} type="file" accept=".gltf" style={{ display: "none", width: "100%" }} onChange={this.handleChange} />
                  <p className="commingSoonItem" style={{ marginTop: '10px' }}>3D Object</p>

                  <div className="commingSoonContainer">
                    <p>AVAILABLE SOON</p>
                  </div>
                </div> */}

                {/* <div className={`formatItem ${this.state.filterType === 'Link' ? "active" : ""}`} onClick={() => {this.setState({ filterType: 'Link'}); this.onFilterTypeChange()}}>
                    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.80248 17.7468C5.35528 16.5071 5.35528 14.4917 6.80248 13.252L10.1878 10.3521L8.57975 8.97466L5.19448 11.8745C2.85864 13.8755 2.85864 17.1233 5.19448 19.1242C7.53032 21.1252 11.3218 21.1252 13.6577 19.1242L17.0429 16.2244L15.4349 14.8469L12.0497 17.7468C10.6024 18.9865 8.24969 18.9865 6.80248 17.7468ZM11.1187 15.4994L17.8892 9.69963L16.1966 8.24969L9.42607 14.0495L11.1187 15.4994ZM13.6577 4.62485L10.2724 7.52473L11.8804 8.90217L15.2657 6.00229C16.7129 4.76259 19.0656 4.76259 20.5128 6.00229C21.96 7.24199 21.96 9.2574 20.5128 10.4971L17.1276 13.397L18.7356 14.7744L22.1208 11.8745C24.4567 9.87363 24.4567 6.62576 22.1208 4.62485C19.785 2.62393 15.9935 2.62393 13.6577 4.62485Z" fill={this.state.filterType === "Link" ? "#fff" : iconColor} />
                    </svg>
                    <p>Link</p>
                </div> */}
              </div>
            </>
          )}

          {this.state.videoFileDisplay && (
            <>
              <p>Uploaded filter</p>
              <div className="uploadedFilterContainer">
                <div className="uploadedVideoContainer">
                  {this.state.filterType.includes("Video") && (
                    <video
                      playsInline
                      controls id="videoStar"
                      onProgress={this.handleVideoLoad}
                      src={this.state.videoFileDisplay}
                    >
                    </video>
                  )}

                  {this.state.filterType.includes("Image") && (
                    <img
                      alt="filter"
                      src={this.state.videoFileDisplay}
                      onLoad={this.handleImageLoad}
                    >
                    </img>
                  )}
                </div>
              </div>
            </>
          )}

          {this.state.videoFileDisplay && (
            <div className="filterSetupContainer">
              <p>Filter setup</p>
              <div className="filterSetupOptions">

                {!this.state.is360 && (
                  <div className={`formatItem ${this.state.staysVisible ? "active" : ""}`} onClick={() => this.setState({ staysVisible: !this.state.staysVisible })}>

                    {this.state.staysVisible ? (
                      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0769 0.763184C6.32426 0.763184 2.26551 3.71933 0.621094 7.89215C2.26551 12.065 6.32426 15.0211 11.0769 15.0211C15.8295 15.0211 19.8883 12.065 21.5327 7.89215C19.8883 3.71933 15.8295 0.763184 11.0769 0.763184ZM11.0769 12.6448C8.45345 12.6448 6.32426 10.5156 6.32426 7.89215C6.32426 5.26869 8.45345 3.1395 11.0769 3.1395C13.7004 3.1395 15.8295 5.26869 15.8295 7.89215C15.8295 10.5156 13.7004 12.6448 11.0769 12.6448ZM11.0769 5.04056C9.49903 5.04056 8.22532 6.31427 8.22532 7.89215C8.22532 9.47002 9.49903 10.7437 11.0769 10.7437C12.6548 10.7437 13.9285 9.47002 13.9285 7.89215C13.9285 6.31427 12.6548 5.04056 11.0769 5.04056Z" fill={this.state.staysVisible ? "#fff" : iconColor} />
                      </svg>
                    ) : (
                      <svg width="22" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.50432 3.36842C11.889 3.36842 13.8245 5.25474 13.8245 7.57895C13.8245 8.12632 13.7121 8.64 13.5134 9.12L16.0364 11.5789C17.3411 10.5179 18.3693 9.14526 19 7.57895C17.5052 3.88211 13.8158 1.26316 9.49568 1.26316C8.28604 1.26316 7.12824 1.47368 6.05684 1.85263L7.92315 3.67158C8.41564 3.47789 8.9427 3.36842 9.50432 3.36842ZM0.864029 1.06947L2.83402 2.98947L3.23147 3.37684C1.79718 4.46316 0.673943 5.91158 0 7.57895C1.49477 11.2758 5.18417 13.8947 9.50432 13.8947C10.8436 13.8947 12.1223 13.6421 13.2888 13.1874L13.6517 13.5411L16.1833 16L17.2806 14.9305L1.96135 0L0.864029 1.06947ZM5.64211 5.72632L6.98136 7.03158C6.93815 7.20842 6.91223 7.39368 6.91223 7.57895C6.91223 8.97684 8.07003 10.1053 9.50432 10.1053C9.69441 10.1053 9.88449 10.08 10.0659 10.0379L11.4052 11.3432C10.8263 11.6211 10.1869 11.7895 9.50432 11.7895C7.1196 11.7895 5.18417 9.90316 5.18417 7.57895C5.18417 6.91368 5.35698 6.29053 5.64211 5.72632V5.72632ZM9.36608 5.06947L12.0878 7.72211L12.105 7.58737C12.105 6.18947 10.9472 5.06105 9.51296 5.06105L9.36608 5.06947Z" fill="#A5A5B0" />
                      </svg>
                    )}
                    <p>Stay visible when target lost</p>
                  </div>
                )}


                {this.state.filterType.includes("Video") || this.state.filterType.includes("link") ? (
                  <>
                    <div className={`formatItem ${this.state.loops ? "active" : ""}`} onClick={() => this.setState({ loops: !this.state.loops })}>

                      {this.state.loops ? (
                        <svg width="20" height="21" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.87181 3.81264V0.96106L4.06969 4.76317L7.87181 8.56529V5.7137C11.0181 5.7137 13.575 8.27062 13.575 11.4169C13.575 12.3769 13.3373 13.2894 12.9096 14.0784L14.2974 15.4661C15.0388 14.297 15.476 12.9092 15.476 11.4169C15.476 7.21554 12.0731 3.81264 7.87181 3.81264ZM7.87181 17.12C4.72556 17.12 2.16863 14.5631 2.16863 11.4169C2.16863 10.4568 2.40627 9.54433 2.834 8.75539L1.44623 7.36762C0.704821 8.53677 0.267578 9.92454 0.267578 11.4169C0.267578 15.6182 3.67047 19.0211 7.87181 19.0211V21.8727L11.6739 18.0706L7.87181 14.2685V17.12Z" fill={this.state.loops ? "#fff" : iconColor} />
                        </svg>
                      ) : (
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2L19 20" stroke="#A5A5B0" strokeWidth="2" />
                          <path d="M10.6264 2.76616V0L6.93821 3.68821L10.6264 7.37641V4.61026C13.6784 4.61026 16.1587 7.09058 16.1587 10.1426C16.1587 11.0738 15.9282 11.959 15.5133 12.7243L16.8595 14.0705C17.5787 12.9364 18.0028 11.5902 18.0028 10.1426C18.0028 6.0671 14.7019 2.76616 10.6264 2.76616ZM10.6264 15.6749C7.57442 15.6749 5.0941 13.1946 5.0941 10.1426C5.0941 9.2113 5.32462 8.32613 5.73954 7.56082L4.39334 6.21463C3.67414 7.34875 3.25 8.69495 3.25 10.1426C3.25 14.218 6.55095 17.519 10.6264 17.519V20.2851L14.3146 16.5969L10.6264 12.9087V15.6749Z" fill="#A5A5B0" />
                        </svg>
                      )}

                      <p>Loop Video</p>
                    </div>


                    {this.props.canActivateSound ? (
                      <div className={`formatItem ${this.state.muteSound ? "" : "active"}`} onClick={() => this.setState({ muteSound: !this.state.muteSound })}>
                        {this.state.muteSound ? (
                          <>
                            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.9308 8.04683C11.9308 6.59613 11.0948 5.35034 9.88183 4.74383V6.55515L11.8899 8.56317C11.9144 8.39925 11.9308 8.22714 11.9308 8.04683V8.04683ZM13.9798 8.04683C13.9798 8.81725 13.8159 9.5385 13.5373 10.2106L14.7749 11.4482C15.3158 10.4319 15.619 9.27623 15.619 8.04683C15.619 4.53893 13.1684 1.60476 9.88183 0.858919V2.5473C12.2505 3.25216 13.9798 5.44869 13.9798 8.04683ZM1.90711 0.67041L0.866211 1.7113L4.74293 5.58802H0.866211V10.5056H4.14462L8.24263 14.6036V9.08772L11.7259 12.571C11.1768 12.9972 10.5621 13.3333 9.88183 13.5382V15.2265C11.0129 14.9725 12.0374 14.4479 12.9062 13.7431L14.5781 15.4232L15.619 14.3823L8.24263 7.00593L1.90711 0.67041ZM8.24263 1.49001L6.52966 3.20298L8.24263 4.91595V1.49001Z"  fill="#A5A5B0" />
                            </svg>

                            <p>Mute Sound</p>
                          </>

                        ) : (
                          <>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 5.77V11.77H4L9 16.77V0.77L4 5.77H0ZM13.5 8.77C13.5 7 12.48 5.48 11 4.74V12.79C12.48 12.06 13.5 10.54 13.5 8.77ZM11 0V2.06C13.89 2.92 16 5.6 16 8.77C16 11.94 13.89 14.62 11 15.48V17.54C15.01 16.63 18 13.05 18 8.77C18 4.49 15.01 0.91 11 0V0Z" fill="#F8F8F8" />
                            </svg>

                            <p>Play Sound</p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={`formatItem disabled`}>
                          <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9308 8.04683C11.9308 6.59613 11.0948 5.35034 9.88183 4.74383V6.55515L11.8899 8.56317C11.9144 8.39925 11.9308 8.22714 11.9308 8.04683V8.04683ZM13.9798 8.04683C13.9798 8.81725 13.8159 9.5385 13.5373 10.2106L14.7749 11.4482C15.3158 10.4319 15.619 9.27623 15.619 8.04683C15.619 4.53893 13.1684 1.60476 9.88183 0.858919V2.5473C12.2505 3.25216 13.9798 5.44869 13.9798 8.04683ZM1.90711 0.67041L0.866211 1.7113L4.74293 5.58802H0.866211V10.5056H4.14462L8.24263 14.6036V9.08772L11.7259 12.571C11.1768 12.9972 10.5621 13.3333 9.88183 13.5382V15.2265C11.0129 14.9725 12.0374 14.4479 12.9062 13.7431L14.5781 15.4232L15.619 14.3823L8.24263 7.00593L1.90711 0.67041ZM8.24263 1.49001L6.52966 3.20298L8.24263 4.91595V1.49001Z"  fill="#A5A5B0" />
                          </svg>

                          <p>Mute Sound</p>
                      </div>
                    )}
                    


                    <div className={`formatItem ${this.state.transparentColor ? "active" : ""}`} onClick={() => this.setState({transparentColor: !this.state.transparentColor})}>
                      {this.state.transparentColor ? (
                        <svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ zIndex: 5 }}>
                          <path d="M9 0C4.03 0 0 4.03 0 9C0 13.97 4.03 18 9 18C9.83 18 10.5 17.33 10.5 16.5C10.5 16.11 10.35 15.76 10.11 15.49C9.88 15.23 9.73 14.88 9.73 14.5C9.73 13.67 10.4 13 11.23 13H13C15.76 13 18 10.76 18 8C18 3.58 13.97 0 9 0ZM3.5 9C2.67 9 2 8.33 2 7.5C2 6.67 2.67 6 3.5 6C4.33 6 5 6.67 5 7.5C5 8.33 4.33 9 3.5 9ZM6.5 5C5.67 5 5 4.33 5 3.5C5 2.67 5.67 2 6.5 2C7.33 2 8 2.67 8 3.5C8 4.33 7.33 5 6.5 5ZM11.5 5C10.67 5 10 4.33 10 3.5C10 2.67 10.67 2 11.5 2C12.33 2 13 2.67 13 3.5C13 4.33 12.33 5 11.5 5ZM14.5 9C13.67 9 13 8.33 13 7.5C13 6.67 13.67 6 14.5 6C15.33 6 16 6.67 16 7.5C16 8.33 15.33 9 14.5 9Z" fill={this.state.transparentColor ? "#fff" : iconColor} />
                        </svg>
                      ) : (
                        <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 3C6.58222 3 3 6.58222 3 11C3 15.4178 6.58222 19 11 19C11.7378 19 12.3333 18.4044 12.3333 17.6667C12.3333 17.32 12.2 17.0089 11.9867 16.7689C11.7822 16.5378 11.6489 16.2267 11.6489 15.8889C11.6489 15.1511 12.2444 14.5556 12.9822 14.5556H14.5556C17.0089 14.5556 19 12.5644 19 10.1111C19 6.18222 15.4178 3 11 3ZM6.11111 11C5.37333 11 4.77778 10.4044 4.77778 9.66667C4.77778 8.92889 5.37333 8.33333 6.11111 8.33333C6.84889 8.33333 7.44444 8.92889 7.44444 9.66667C7.44444 10.4044 6.84889 11 6.11111 11ZM8.77778 7.44444C8.04 7.44444 7.44444 6.84889 7.44444 6.11111C7.44444 5.37333 8.04 4.77778 8.77778 4.77778C9.51556 4.77778 10.1111 5.37333 10.1111 6.11111C10.1111 6.84889 9.51556 7.44444 8.77778 7.44444ZM13.2222 7.44444C12.4844 7.44444 11.8889 6.84889 11.8889 6.11111C11.8889 5.37333 12.4844 4.77778 13.2222 4.77778C13.96 4.77778 14.5556 5.37333 14.5556 6.11111C14.5556 6.84889 13.96 7.44444 13.2222 7.44444ZM15.8889 11C15.1511 11 14.5556 10.4044 14.5556 9.66667C14.5556 8.92889 15.1511 8.33333 15.8889 8.33333C16.6267 8.33333 17.2222 8.92889 17.2222 9.66667C17.2222 10.4044 16.6267 11 15.8889 11Z" fill="#A5A5B0"/>
                            <path d="M1 2L19 20" stroke="#A5A5B0" strokeWidth="2"/>
                            <path d="M2 1L20 19" stroke="#090C08" strokeWidth="2"/>
                        </svg>                              
                      )}
                      <p> Transparent Color</p>
                    </div>
                  </>
                ) : null}

                {this.state.filterType.includes("360") || this.state.filterType.includes("link") ? (
                  <div className="formatItem360">
                    <input
                      type="text"
                      pattern='[0-9]{0,5}'
                      maxLength={3}
                      placeholder="0"
                      value={this.state.rotationDegrees === null ? "" : this.state.rotationDegrees}
                      onChange={(e) => this.handleAddRotationInDegrees(e)}
                    />
                    <span></span>
                    <p>Rotate view in degrees</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}

          {this.state.transparentColor && (
            <div className="filterTransparentColor">
              <p>Choose transparent color</p>
              <div className="filterTransparentInputs">
                <input
                  aria-label="color"
                  type="color"
                  value={this.state.transparentColorCode}
                  onChange={e => this.setState({ transparentColorCode: e.target.value })}
                />

                <input
                  placeholder="Transparent Color"
                  aria-label="transparent color"
                  type="text"
                  value={this.state.transparentColorCode}
                  onChange={e => this.setState({ transparentColorCode: e.target.value })}
                />
              </div>
            </div>
          )}

          <div className="filterNameContainer">
            <p>Add filter name</p>
            <input
              placeholder="Filter name"
              aria-label="video"
              aria-describedby="basic-addon1"
              type="text"
              value={this.state.videoName}
              onChange={this.handleVideoNameChange}
            />
          </div>

          <div className="filterNameInputs">
            <button onClick={this.resetState}>RESET</button>
            <button 
              onClick={this.addVideoHandler}
              className={`${!this.state.validDuration ? "disabled" : ""}`}
              disabled={!this.state.validDuration}
            >
              ADD FILTER
            </button>
          </div>

          {this.state.preview && (
            <div className="previewContainer">
              <div className="previewContainerHeader">
                <h4>Filter Preview</h4>
                <div onClick={() => this.setState({ preview: false })}>X</div>
              </div>

              <div className="videoContainer">
                {this.state.filterType.includes("Video") && (
                  <video
                    playsInline
                    controls id="videoStar"
                    onProgress={this.handleVideoLoad}
                    // src={this.state.videoFileDisplay}
                    src={this.state.videoUrl}
                  >
                  </video>
                )}

                {this.state.filterType.includes("Image") && (
                  <img
                    alt="filter"
                    onProgress={this.handleVideoLoad}
                    src={this.state.videoFileDisplay || undefined}
                  >
                  </img>
                )}
              </div>
            </div>
          )}

        </div>
      </>
    );
  }
}

export default VideoUpload;