import { useCallback } from "react";
import ExperienceModel from "src/models/ExperienceModel";
import UploadImageFileModel from "src/models/UploadImageFileModel";
import getPreviewUrl, { getStorageUrl } from 'src/services/config-reader';


export const usePreview = (experience: ExperienceModel | undefined) => {
  const validateExperience = useCallback(() => {
    if (!experience) return;

    if (experience.title.length === 0) {
      return false;
    }

    // if (experience.trackables.length === 0) {
    //   return false;
    // }

    if (experience.videos.length === 0) {
      return false;
    }

    let trackablesValid = true;

    experience.trackables.map(function (trackable: UploadImageFileModel) {
      if (trackable.fileUploadError) {
        trackablesValid = false;
      }

      if (trackable.stillUploading) {
        trackablesValid = false;
      }

      return trackable;
    });

    if (!trackablesValid) {
      return false;
    }

    return true;
  },[experience]);

  return useCallback(() => {
    if (!experience) return;
    const liveExperiencePath = `${process.env.REACT_APP_Experience_Store}/${experience.uuid}`;

    const validationResult = validateExperience();

    if (validationResult) {
      if (liveExperiencePath !== "") {
        const newPageUrl =
        getPreviewUrl() +
        `?exp=${getStorageUrl()}` + liveExperiencePath;
        window.open(newPageUrl, '_blank');
      }
    } else {
      console.error("ERROR")
    }
  }, [experience, validateExperience])
}