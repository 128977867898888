import React from "react";
import './LogoUploadButton.css'

interface IProps {
    onFileSelected: ((e: any) => void),
    buttonText: string,
    fileTypes: string,
    buttonId: string
}

export const LogoUploadButton: React.FC<IProps> = ({ onFileSelected, buttonText, fileTypes, buttonId }) => {
  return (
    <div>
      <label htmlFor={buttonId} className="logoUploadButton">
          {buttonText}
      </label>
      <input
        type="file"
        id={buttonId}
        accept={fileTypes}
        style={{ display: "none" }}
        onChange={onFileSelected}
      />
    </div>
  );
}