import { VideoSpec } from "./ExperienceModel";

export default class UploadVideoFileModel implements VideoSpec {
    id = "";
    name = "";
    url = "";
    videoFile: any;
    videoFileDisplay: any;
    videoFileName = "";
    selectedTrackableId = "";
    three60 = false;
    muted = false;
    staysVisible = false;
    loops = false;
    rotation?: number | undefined;
    transparentColor = false;
    transparentColorCode = "#00FF00";
    transparency?: {color: string, similarity: number, smoothness: number}
    filterType: "Video" | "360° Image" | "360° Video" | "" | "3D Object" = "";


    uploadedSuccessfully = false;
    stillUploading = false;
    uploadedError = false;
}
