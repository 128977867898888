const isDevelopment = function () {
  return process.env.NODE_ENV === 'development';
};

const getImageTrackerProUrl = function () {
  let url = '';
  if (process !== undefined && process !== null) {
    if (process.env !== undefined && process.env !== null) {
      if (
        process.env.REACT_APP_ImageTrackProUrl !== undefined &&
        process.env.REACT_APP_ImageTrackProUrl !== null
      ) {
        url = process.env.REACT_APP_ImageTrackProUrl.toString();
      }
    }
    return url;
  }
};

export { getImageTrackerProUrl, isDevelopment as default };
