import React, { useState } from 'react'
import UserModel from '../../models/UserModel';

import { StorageService } from 'src/services/StorageService';
import { Constants } from 'src/models/Constants';
import firebase from 'firebase/app';
import 'firebase/auth';

import './LogginPage.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Spinner } from 'react-bootstrap';

interface IProps {
    user?: UserModel;
    origin?: string;
    handleContinueAsGuest?: () => void;
}

const LogginPage: React.FC<IProps> = ({ user, origin, handleContinueAsGuest }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoadingLogin, setLoadingLogin] = useState(false);
    const [isLoadingRegister, setLoadingRegister] = useState(false);

    const notify = (message: string) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    };

    const createUser = (authResult: any) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = authResult.credential.accessToken;
        const providerId = authResult.credential.providerId;
        // The signed-in user info.
        const user = authResult.additionalUserInfo.profile;
        const userModel = new UserModel();

        userModel.email = user.email;
        userModel.firstName = user.given_name;
        userModel.lastName = user.family_name;

        userModel.name = user.given_name + ' ' + user.family_name;
        userModel.id = user.id;
        userModel.loginProvider = providerId;
        userModel.accessToken = token;

        const userJson = JSON.stringify(userModel);

        StorageService.Store(Constants.UserKey, userJson);
    };

    const createUserWithEmail = (authResult: any) => {
        let atPosition = authResult.email.split('').indexOf("@");
        let userName = authResult.email.substring(0, atPosition);

        const userModel = new UserModel();

        userModel.email = authResult.email;
        userModel.firstName = userName;
        userModel.name = userName;
        userModel.id = authResult.uid;
        userModel.loginProvider = 'email';

        const userJson = JSON.stringify(userModel);

        StorageService.Store(Constants.UserKey, userJson);
    };

    const handleLoginWithGoogleClick = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase
            .auth()
            .signInWithPopup(provider)
            .then(function (result: any) {
                createUser(result);
                handleForwards();
            })
            .catch(function (error) {
                notify(error.message);
            });
    };

    const handleLoginWithFaceBookClick = () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then(function (result: any) {
                createUser(result);
                handleForwards();
            })
            .catch(function (error) {
                notify(error.message);
            });
    };

    const handleForwards = () => {
        if (origin === "/login") {
            window.location.href = '/';
        } else if (handleContinueAsGuest) {
            handleContinueAsGuest();
        }
    }

    const handleLogin = () => {
        setLoadingLogin(true);
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                var user = userCredential.user;
                createUserWithEmail(user);
                handleForwards();
                setLoadingLogin(false);
            })
            .catch((error) => {
                notify(error.message);
                setLoadingLogin(false);
            });
    };

    const handleRegister = () => {
        setLoadingRegister(true);
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                var user = userCredential.user;
                createUserWithEmail(user);
                handleForwards();
                setLoadingRegister(false);

            })
            .catch((error) => {
                notify(error.message);
                setLoadingRegister(false);
            });
    };

    const continueAsGuest = () => {
        handleForwards();
    }

    return (
        <div className="logginPageContainer">
            <h1>
                You are not <strong>Logged In</strong>, <br />
                do you have an account?
            </h1>

            <main>
                <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} onKeyUp={
                    (e) => {
                        if (e.key === "Enter") {
                            // Cancel the default action, if needed
                            e.preventDefault();
                            handleLogin()
                        }
                    }} placeholder='Password' />

                <div className="loginAndRegister">
                    <button
                        onClick={handleRegister}
                        className="registerButton"
                    >
                        {isLoadingRegister ? (
                            <Spinner
                                animation='border'
                                role='status'
                                size='sm'
                            />
                        ) : (
                            <p>REGISTER</p>
                        )}
                    </button>
                    <button
                        className="loginButton"
                        onClick={() => handleLogin()}
                    >
                        {isLoadingLogin ? (
                            <Spinner
                                animation='border'
                                role='status'
                                size='sm'
                            />
                        ) : (
                            <p>LOGIN</p>
                        )}
                    </button>

                </div>

                <div className="loginWith">
                    <span></span>
                    <p>Login with</p>
                    <span></span>
                </div>

                <div className="googleAndFacebook">
                    <button className="logInWithGoogle" onClick={handleLoginWithGoogleClick}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.93521 7.30007V10.7511H13.7272C13.2812 12.9441 11.4142 14.2041 8.93521 14.2041C6.04776 14.1643 3.72787 11.8123 3.72787 8.92457C3.72787 6.03684 6.04776 3.68481 8.93521 3.64507C10.1354 3.64363 11.2987 4.06014 12.2252 4.82307L14.8252 2.22307C11.8428 -0.398861 7.48721 -0.736093 4.13677 1.39552C0.786319 3.52712 -0.753767 7.61528 0.357458 11.4277C1.46868 15.2401 4.96416 17.8605 8.93521 17.8581C13.4022 17.8581 17.4642 14.6091 17.4642 8.92407C17.4573 8.37671 17.3902 7.83177 17.2642 7.29907L8.93521 7.30007Z" fill="#F0386B" />
                        </svg>
                        Google
                    </button>
                    <button className="logInWithFacebook" onClick={handleLoginWithFaceBookClick}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 9.99863C0.00116354 14.918 3.5784 19.1067 8.437 19.8776V12.8886H5.9V9.99863H8.44V7.79863C8.32645 6.75617 8.68252 5.71722 9.41164 4.96356C10.1408 4.2099 11.1674 3.81964 12.213 3.89863C12.9635 3.91075 13.7122 3.97759 14.453 4.09863V6.55763H13.189C12.7538 6.50063 12.3164 6.64435 11.9998 6.94829C11.6832 7.25224 11.5218 7.68351 11.561 8.12063V9.99863H14.332L13.889 12.8896H11.561V19.8776C16.8155 19.0472 20.5001 14.2484 19.9455 8.9577C19.3909 3.667 14.7913 -0.263445 9.47882 0.0138048C4.16636 0.291054 0.000844477 4.67893 0 9.99863Z" fill="#4C77BC" />
                        </svg>
                        Facebook
                    </button>
                </div>

                <div className="orGuest">
                    <span></span>
                    <p>or</p>
                    <span></span>
                </div>

                <button onClick={continueAsGuest}>CONTINUE AS GUEST</button>

            </main>

        </div>
    )
}

export default LogginPage;
