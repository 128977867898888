import React from 'react'

import './styles.css';

export default function Footer() {
    return (
        <footer className="footerContainer">
            {/* <p>Made </p>
            <svg width="15" height="15" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.57601e-08 2.40029C-0.000111795 1.75603 0.262073 1.13878 0.727566 0.687412C1.19306 0.236048 1.82276 -0.0115187 2.475 0.000412082C3.2478 -0.00364105 3.98519 0.320029 4.5 0.889255C5.01481 0.320029 5.7522 -0.00364105 6.525 0.000412082C7.17724 -0.0115187 7.80694 0.236048 8.27243 0.687412C8.73793 1.13878 9.00011 1.75603 9 2.40029C9 4.78061 6.12945 6.57785 4.5 8C2.87415 6.56585 3.57601e-08 4.78239 3.57601e-08 2.40029Z" fill={iconColor}/>
            </svg>
            <a target="_blank" rel="noopener noreferrer" href="https://tripod-digital.co.nz/">
                <p>by NumbThumb</p>
            </a> */}
        </footer>
    )
}
