import { loadStripe } from '@stripe/stripe-js';
import { Product, db } from './firebase';

const taxRates = ['txr_1Hc10bAE7lwkKZPTAbf7UelY'];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

// Checkout handler
export const subscribe = async function (
  product: Product,
  priceId: string,
  currentUser: string /* this needs to be the user id from firebase */,
  experienceId: string
) {
  const selectedPrice = product.prices[priceId];

  if (!selectedPrice) throw new Error('No price for product');
  // For all prices we set quantity to 1.
  // selectedPrice.quantity = 1;
  const checkoutSession = {
    // collect_shipping_address: true,
    tax_rates: taxRates,
    allow_promotion_codes: true,
    line_items: [{ price: priceId, quantity: 1 }],
    success_url: `${window.location.origin}/create-experience/success/?session_id={CHECKOUT_SESSION_ID}`, // TODO: add our success URL here
    cancel_url: window.location.origin + '/create-experience', // TODO: add our error URL here
    metadata: {
      experienceId
    }
  };

  // For one time payments set mode to payment.
  if (selectedPrice.type === 'one_time') {
    (checkoutSession as any).mode = 'payment';
  }
  const docRef = await db
    .collection('customers')
    .doc(currentUser)
    .collection('checkout_sessions')
    .add(checkoutSession);
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot(async (snap) => {
    const { error, sessionId } = snap.data() as any;
    if (error) {
      throw new Error(`An error occured: ${error.message}`);
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error(`An error occured: Stripe error`);
      }
      stripe.redirectToCheckout({ sessionId });
    }
  });
};
