import React, { useState, useEffect, useContext } from "react";
import '../file-upload-button/FileUploadButton.css'
import UploadImageFileModel from "src/models/UploadImageFileModel";
import { Spinner } from "react-bootstrap";
import compressAndUploadImageFile from "../../services/upload-illustration.service";



import './styles/target-image-card.css';
import ExperienceModel from "src/models/ExperienceModel";
import { ExperienceContext } from "src/contexts/ExperienceContext";

interface IProps {
    experience: ExperienceModel;
    uploadImage: UploadImageFileModel;
    deleteIllustration: (UploadIllustrationModel: UploadImageFileModel) => void;
    isStillUploading: boolean;
    setIsStillUploading: (isUploading: boolean) => void;
    uploadListOfImageTarget: boolean;
}

// const ImageFileComponent: React.FC<IProps> = ({ experience, uploadImage, deleteIllustration, setIsStillUploading, uploadListOfImageTarget }) => {
const ImageFileComponent: React.FC<IProps> = ({ uploadImage, deleteIllustration, setIsStillUploading, uploadListOfImageTarget, experience }) => {
    const { saveExperienceToLocalStore } = useContext(ExperienceContext);

    // ntf refers to the service that marks the illustration for detection and provides the location
    // of the AR popout video within the users camera view.

    const [ntfProcessingSuccess, setNtfProcessingSuccess] = useState(false);
    const [ntfProcessingInProgress, setNtfProcessingInProgress] = useState(false);
    const [ntfUploadedAttempted, setNtUploadedAttempted] = useState(false);
    const [ntfProcessingError, setNtntProcessingError] = useState(false);
    const [popUpFile, setPopFile] = useState("");
   

    const handleConfirmCallback = () => {
        deleteIllustration(uploadImage);
    }

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if(uploadImage.url !== "") {            
                setPopFile(uploadImage.url + '.jpeg');
            } else if(popUpFile !== "") {
                const selectedTargetImage = experience.trackables.find(o => o.id ===  uploadImage.id);
                if(selectedTargetImage) {
                    setPopFile(selectedTargetImage.popUpFile);
                }
            } else if(uploadImage.popUpFile) {            
                setPopFile(uploadImage.popUpFile);
            } 
        }
        
        return () => { unmounted = true };
    }, [ 
        experience, 
        popUpFile, 
        uploadImage.id,
        uploadImage.popUpFile,
        uploadImage.url
    ])

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if(!uploadImage.fileUploadedSuccessfully) {
                const handleNtfProcessingComplete = (
                    success: boolean,
                    message: string,
                    url: string) => {
                    uploadImage.stillUploading = false;
                    setIsStillUploading(false);
        
                    if (success) {
                        uploadImage.fileUploadedSuccessfully = true;
                        uploadImage.stillUploading = false;
                        uploadImage.url = url;
                        setNtfProcessingInProgress(false);
                        setNtfProcessingSuccess(true);
                        setNtntProcessingError(false);
                        setIsStillUploading(false);
                        saveExperienceToLocalStore(experience);
                    } else {
                        uploadImage.fileUploadError = true;
                        uploadImage.stillUploading = false;
                        setNtfProcessingInProgress(false);
                        setNtntProcessingError(true);
                        setIsStillUploading(false);
                        saveExperienceToLocalStore(experience);
                    }
                }
        
                if (!ntfUploadedAttempted) {
                    // if an instance of a file is provided then the user 
                    // has uploaded a file to the browser for upload
                    setNtUploadedAttempted(true);
                    if (uploadImage.selectedFile instanceof Blob) {
        
                        const src = window.URL.createObjectURL(uploadImage.selectedFile as Blob);
                        setPopFile(src);
        
                        uploadImage.popUpFile = src;
                        uploadImage.stillUploading = true;
                        setIsStillUploading(true);
                        compressAndUploadImageFile(
                            uploadImage.selectedFile,
                            setNtfProcessingInProgress,
                            handleNtfProcessingComplete);
                    } else {
        
                        if (uploadImage.url) {
                            uploadImage.stillUploading = true;
                            setIsStillUploading(true);
                            setNtfProcessingInProgress(true);
                            (async () => {
                                const resourceResponse = await fetch(uploadImage.url + ".jpeg");
                                uploadImage.stillUploading = false;
                                setIsStillUploading(false);
                                setNtfProcessingInProgress(false);
                                if (resourceResponse) {
                                    if (resourceResponse.status === 200) {
        
                                        uploadImage.fileUploadedSuccessfully = true;
                                        uploadImage.stillUploading = false;
        
                                        const blob = await resourceResponse.blob();
                                        const src = window.URL.createObjectURL(blob);
                                        setPopFile(src);
                                        uploadImage.popUpFile = src;
                                        setNtfProcessingInProgress(false);
                                        setNtfProcessingSuccess(true);
                                        setNtntProcessingError(false);
                                        setIsStillUploading(false);
                                        saveExperienceToLocalStore(experience);
                                        
                                    } else {
                                        setNtfProcessingInProgress(false);
                                        setNtntProcessingError(true);
                                        setIsStillUploading(false);
                                        
                                        uploadImage.stillUploading = false;
                                        uploadImage.fileUploadError = true;
                                        saveExperienceToLocalStore(experience);
                                    }
                                }
                            })();
                        }
                    }
                }
                else if (ntfProcessingInProgress) {
                    uploadImage.stillUploading = true;
                    setIsStillUploading(true);
                    saveExperienceToLocalStore(experience);
                } else if (ntfProcessingError) {
                    setNtfProcessingInProgress(false);
                    uploadImage.stillUploading = false;
                    setIsStillUploading(false);
                    uploadImage.fileUploadError = true;
                    saveExperienceToLocalStore(experience);
                }
            }
        }
        return () => { unmounted = true };
    }, [
        ntfProcessingInProgress,
        ntfProcessingError,
        setIsStillUploading,
        ntfUploadedAttempted,
        uploadImage,
        uploadListOfImageTarget,
        experience,
        saveExperienceToLocalStore
    ])

    return (
        <div className="targetImageCard">
            <div className="targetImageCardPictureAndName">
                <div className="targetImageCardPictureContainer">
                {popUpFile.length > 0 ? (
                    <img className="targetPicture" src={popUpFile} alt="Target" />
                ) : (null)}
                </div>

                <p>{uploadImage.name}</p>
            </div>

            <div className="targetImageCardStatus">
                {ntfProcessingInProgress && (
                    <div className="uploading">
                        <p>Uploading</p>
                        <Spinner style={{ marginLeft: '20px'}} animation="border" role="status" size="sm">
                            <span className="sr-only">Uploading</span>
                        </Spinner>
                    </div>)}

                {ntfProcessingError && (
                    <p className="uploading">Sorry there was a problem uploading this file.</p>
                )}

                {ntfProcessingSuccess && (
                    <p className="uploading">Uploaded</p>
                )}
                {!ntfProcessingInProgress && !ntfProcessingSuccess && (uploadImage.popUpFile && uploadImage.popUpFile.length) > 0 ? (
                    <p className="uploading">Uploaded</p>
                ) : null}
                <button
                    className='delete-button'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (window.confirm('Are you sure you wish to delete this item?'))
                            handleConfirmCallback()
                    }}
                >
                    Change
                </button>
            </div>
        </div>
    );
}

export default ImageFileComponent;