import React, { createContext, useState, ReactNode, useEffect } from 'react';
import ExperienceModel from 'src/models/ExperienceModel';
import UploadImageFileModel from 'src/models/UploadImageFileModel';
import UserModel from 'src/models/UserModel';
import getPreviewUrl, { getStorageUrl } from 'src/services/config-reader';
import { uploadExperience } from 'src/services/experience-upload.service';
import QRCode from 'qrcode';

import { ExperienceFileNameService } from 'src/services/experience-file-name.service';
import { checkForUploadingFiles } from 'src/services/FileUploadingValidationService';

interface ExperienceProviderProps {
  children: ReactNode;
  user?: UserModel;
  setUserModels: (user: UserModel) => void;
}

interface ExperienceContextData {
  qrCodeImg: string;
  createExperienceStep: string;
  qrCodeLink: string;
  originalExperienceName: string;
  errorOccured: boolean;
  savingExperienceSuccess: boolean;
  freePlan: boolean;
  savingExperience: boolean;
  validationError: string;
  experienceName: string;
  savedExperienceUrl: string;
  errorMessage: string;
  productUsed: string;
  listOfExperiences: ExperienceModel[] | undefined;
  experience: ExperienceModel;
  reloadList: boolean;
  canActivateSound: boolean;
  setCanActivateSound: (status: boolean) => void;
  setReloadList: (status: boolean) => void;
  setListOfExperiences: (list: ExperienceModel[] | undefined) => void;
  setqrCodeImg: (qrCode: string) => void;
  setProductUsed: (product: string) => void;
  setExperienceName: (name: string) => void;
  setCreateExperienceStep: (step: string) => void;
  setOriginalExperienceName: (name: string) => void;
  setErrorOccured: (error: boolean) => void;
  setSavingExperience: (error: boolean) => void;
  setFreePlan: (free: boolean) => void;
  setSavingExperienceSuccess: (success: boolean) => void;
  setErrorMessage: (error: string) => void;
  setVaidationMessage: (message: string) => void;
  setSavedExperienceUrl: (url: string) => void;
  setqrCodeLink: (link: string) => void;
  setExperience: (experience: ExperienceModel) => void;
  deleteLocalStore: () => void;
  saveExperienceToLocalStore: (experience: ExperienceModel) => void;
  handleGoHome: () => void;
  saveExperience: (experience: ExperienceModel) => void;
}

export const ExperienceContext = createContext({} as ExperienceContextData);

export function ExperienceProvider({
  children,
  user
}: ExperienceProviderProps) {
  const [createExperienceStep, setCreateExperienceStep] = useState(
    'target-image'
  );
  const [experience, setExperience] = useState(new ExperienceModel());
  const [qrCodeImg, setqrCodeImg] = useState('');
  const [qrCodeLink, setqrCodeLink] = useState('');
  const [savedExperienceUrl, setSavedExperienceUrl] = useState('');
  const [validationError, setVaidationMessage] = useState('');
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [originalExperienceName, setOriginalExperienceName] = useState('');
  const [savingExperienceSuccess, setSavingExperienceSuccess] = useState(false);
  const [freePlan, setFreePlan] = useState(false);
  const [canActivateSound, setCanActivateSound] = useState(true);
  const [savingExperience, setSavingExperience] = useState(false);
  const [experienceName, setExperienceName] = useState(experience.title);
  const [productUsed, setProductUsed] = useState('');

  const [reloadList, setReloadList] = useState(false);

  const [listOfExperiences, setListOfExperiences] = useState<ExperienceModel[] | undefined>();

  const saveExperienceToLocalStore = (experience: ExperienceModel) => {
    localStorage.setItem('experience', JSON.stringify(experience));
  };

  const deleteLocalStore = () => {
    localStorage.setItem('experience', '');
    localStorage.setItem('qrCodeResult', '');
    localStorage.setItem('step', 'success');
    localStorage.setItem('step', 'target-image');
    localStorage.setItem('isEditing', '');
    localStorage.setItem('originalExperienceName', '');
    localStorage.setItem('products', "");
  };

  const handleGoHome = () => {
    deleteLocalStore();
    window.location.href = '/';
  };

  const removeLocalFileDisplayPath = (experience: ExperienceModel) => {
    experience.trackables.forEach((value: UploadImageFileModel) => {
      if (value) {
        value.selectedFilDisplay = '';
      }
    });
    saveExperienceToLocalStore(experience);
  };

  const setVideoIndexTrackable = function () {
    let illustrationId = '';

    experience.videos.forEach((video, index) => {
      illustrationId = video.selectedTrackableId;

      if (illustrationId) {
        const illustration = experience.trackables.find(
          t => t.id === illustrationId
        );

        if (illustration) {
          illustration.video = index;
          illustration.videoId = video.id;
          saveExperienceToLocalStore(experience);
        }
      }
    });
  };

  const saveExperience = async (experience: ExperienceModel) => {
    setVaidationMessage('');
    setErrorMessage('');
    setErrorOccured(false);

    // console.log("1 - experience", experience);
    if (experience) {
      const validationResult = ExperienceFileNameService.ValidateExperienceName(
        experience.title,
        originalExperienceName
      );
      // console.log("Name validation: ", validationResult);

      if (validationResult.success) {
        const filesUploaded = checkForUploadingFiles(experience);
        // console.log("Files uploaded: ", filesUploaded);

        if (filesUploaded.success) {
          removeLocalFileDisplayPath(experience);
          setVideoIndexTrackable();

          const emailValidation = user
            ? ExperienceFileNameService.ValidateUserEmail(user.email)
            : ExperienceFileNameService.ValidateUserEmail('guest@gmail.com');

          // console.log("User Email: ", emailValidation);

          if (emailValidation.success) {
            const newFileName = user
              ? ExperienceFileNameService.BuildNewExperienceFilename(
                experience.uuid,
                experience.title,
                user.email,
                originalExperienceName
              )
              : ExperienceFileNameService.BuildNewExperienceFilename(
                experience.uuid,
                experience.title,
                'guest@email.com',
                originalExperienceName
              );

            experience.filePath = newFileName.filePath;
            experience.email = user ? user.email : 'guest@gmail.com';

            setSavingExperience(true);
            uploadExperience(
              experience,
              (success: boolean, donwloadUrl: string) => {
                setSavingExperience(false);
                if (success) {
                  setSavingExperienceSuccess(true);
                  setCreateExperienceStep('success');

                  if (donwloadUrl) {
                    console.log('downloadURL', donwloadUrl);
                    setqrCodeLink(`${getPreviewUrl()}?exp=${getStorageUrl()}${process.env.REACT_APP_Experience_Store}/${experience.uuid}`);
                    setSavedExperienceUrl(donwloadUrl);
                  } else {
                    setErrorOccured(true);
                  }
                } else {
                  setErrorOccured(true);
                }
              }
            );
          } else {
            setErrorOccured(true);
            setErrorMessage(emailValidation.message);
          }
        } else {
          setErrorOccured(true);
          let validationMessages = '';
          filesUploaded.messages.map((message: string) => {
            validationMessages += message + ' ';
            return true;
          });

          setErrorMessage(validationMessages);
        }
      } else {
        setErrorOccured(true);
        setErrorMessage(validationResult.message);
      }
    }
  };

  useEffect(() => {
    if (qrCodeLink !== '') {
      const generateQrCodeEffect = async () => {
        try {
          const qrCodeResult = await QRCode.toDataURL(qrCodeLink);
          localStorage.setItem('qrCodeResult', qrCodeResult);
          setqrCodeImg(qrCodeResult);
          console.log(qrCodeResult);
          return qrCodeResult;
        } catch (err) {
          console.error(err);
        }
      };
      generateQrCodeEffect();
    }
  }, [qrCodeLink]);

  return (
    <ExperienceContext.Provider
      value={{
        experience,
        setExperience,
        deleteLocalStore,
        saveExperienceToLocalStore,
        qrCodeImg,
        setqrCodeImg,
        qrCodeLink,
        setqrCodeLink,
        savedExperienceUrl,
        setSavedExperienceUrl,
        validationError,
        setVaidationMessage,
        errorOccured,
        setErrorOccured,
        errorMessage,
        setErrorMessage,
        handleGoHome,
        originalExperienceName,
        setOriginalExperienceName,
        savingExperienceSuccess,
        setSavingExperienceSuccess,
        savingExperience,
        setSavingExperience,
        createExperienceStep,
        setCreateExperienceStep,
        saveExperience,
        experienceName,
        setExperienceName,
        productUsed,
        setProductUsed,
        freePlan,
        setFreePlan,
        listOfExperiences,
        setListOfExperiences,
        canActivateSound,
        setCanActivateSound,
        reloadList,
        setReloadList
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}
