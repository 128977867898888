import React, { useState, useEffect, useContext, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import UserModel from 'src/models/UserModel';
import LogginPage from 'src/components/loggin-page';
import ExperienceModel from 'src/models/ExperienceModel';
import { fetchProducts, Product } from 'src/services/firebase';
import SubscriptionPage from 'src/components/subscription-page';
import UploadImage from '../components/image-upload/UploadImage';
import VideoUpload from 'src/components/video-upload/UploadVideo';
import UploadImageFileModel from 'src/models/UploadImageFileModel';
import UploadVideoFileModel from 'src/models/UploadVideoFileModel';
import ImageFileComponent from 'src/components/image-upload/ImageFileComponent';
import VideoFileComponent from 'src/components/video-upload/VideoFileComponent';
import { ExperienceFileNameService } from 'src/services/experience-file-name.service';
import { checkForUploadingFiles, } from 'src/services/FileUploadingValidationService';
import ExperienceNameInput from 'src/components/experience-name-input/ExperienceNameInput';
import { ExperienceDetailsComponent } from 'src/components/experience-details/ExperienceDetailsComponent';

import createAR from '../assets/images/createAR.png'
import imageTargetPng from '../assets/images/imageTarget.png'
import videoFilterPng from '../assets/images/videoFilter.png'

import '../App.css';
import './styles/create-experience.css';
import { iconColor } from 'src/config';
import { ExperienceContext } from 'src/contexts/ExperienceContext';
import Success from 'src/components/success';
import { ModalContext } from 'src/contexts/ModalContext';

import Modal from 'react-modal';
import isDevelopment from 'src/services/utilities';
import { Constants } from 'src/models/Constants';
import { usePreview } from 'src/components/success/usePreview';
import ExperienceStep from 'src/components/experience-step/ExperienceStep';

interface IProps {
  user: UserModel;
  experience: ExperienceModel;
}

const CreateExperience: React.FC<IProps> = ({ user, experience }) => {
  const {
    setExperience,
    saveExperienceToLocalStore,
    validationError,
    setVaidationMessage,
    errorOccured,
    setErrorOccured,
    errorMessage,
    setErrorMessage,
    originalExperienceName,
    setOriginalExperienceName,
    savingExperienceSuccess,
    savingExperience,
    createExperienceStep,
    setCreateExperienceStep,
    experienceName,
    setExperienceName,
    setCanActivateSound,
    canActivateSound
  } = useContext(ExperienceContext);

  const {
    modalIsOpen,
    closeModal,
    customStyles,
    setModalIsOpen } = useContext(ModalContext);

  const firstRender = useRef(true);

  const [products, setProducts] = useState<Product[]>();
  const [videos, setVideos] = useState(new Array<UploadVideoFileModel>());
  const [illustrations, setIllustrations] = useState(new Array<UploadImageFileModel>());
  const [continueAsGuest, setContinueAsGuest] = useState(false);
  const [experienceLoaded, setExperienceLoaded] = useState(false);
  const [isStillUploading, setIsStillUploading] = useState(false);
  const [addingMoreFilters, setAddingMoreFilters] = useState(false);
  const [uploadListOfFilters, setUploadListOfFilters] = useState(false);
  const [addingMoreTargetImages, setAddingMoreTargetImages] = useState(false);
  const [isFiltersStillUploading, setIsFiltersStillUploading] = useState(false);
  const [uploadListOfImageTarget, setUploadListOfImageTarget] = useState(false);

  const preview = usePreview(experience);

  const handleExperienceNameChange = (e: any) => {
    setVaidationMessage('');
    setErrorOccured(false);
    const newName = e.target.value;
    setExperienceName(newName);
    experience.title = newName;
    saveExperienceToLocalStore(experience);

    const validationResult = ExperienceFileNameService.ValidateExperienceName(
      experience.title,
      originalExperienceName
    );
    setErrorMessage(validationResult.message);
  };

  const handleContinueAsGuest = () => {
    setContinueAsGuest(true);
    setCreateExperienceStep('subscription');
  }

  const addIllustration = (illustration: UploadImageFileModel) => {
    if (illustration) {
      const illustrationsCopy = Object.assign([], illustrations);
      illustrationsCopy.push(illustration);

      setIllustrations(illustrationsCopy);

      addIllustrationToExperience(illustration);
      console.log('experience', experience)
      saveExperienceToLocalStore(experience);
    }
  }

  const addIllustrationToExperience = (illustration: UploadImageFileModel) => {
    setVaidationMessage("");

    if (!experience.trackables) {
      experience.trackables = new Array<UploadImageFileModel>();
    }

    experience.trackables.push(illustration);
  }

  const deleteIllustrationFromExperience = (illustration: UploadImageFileModel) => {
    setVaidationMessage("");

    // if (experience.trackables) {
    //   experience.trackables = new Array<UploadImageFileModel>();
    // }

    experience.trackables = experience.trackables.filter(item => item.id !== illustration.id);

    setIllustrations(experience.trackables);
    setUploadListOfImageTarget(true);
    saveExperienceToLocalStore(experience);
  }

  const addVideoToExperience = (videos: UploadVideoFileModel) => {
    setVaidationMessage("");

    if (!experience.videos) {
      experience.videos = new Array<UploadVideoFileModel>();
    }

    experience.videos.push(videos);
    saveExperienceToLocalStore(experience);
  }

  const deleteVideoFromExperience = (video: UploadVideoFileModel) => {
    setVaidationMessage("");

    if (!experience.videos) {
      experience.videos = new Array<UploadVideoFileModel>();
    }

    experience.videos = experience.videos.filter(item => item.id !== video.id);

    let filterWithSound = false;

    experience.videos.forEach(video => {
      if (!video.muted) {
        filterWithSound = true;
      }
    })

    setCanActivateSound(!filterWithSound);

    setVideos(experience.videos);
    saveExperienceToLocalStore(experience);
  }

  const addVideo = (video: UploadVideoFileModel) => {
    setVaidationMessage("");

    if (video) {
      const videosCopy = Object.assign([], videos);
      videosCopy.push(video);
      setVideos(videosCopy);
      addVideoToExperience(video);
      saveExperienceToLocalStore(experience);

      if (video.muted === false) {
        setCanActivateSound(false);
      }
    }
  }

  const removeLocalFileDisplayPath = (experience: ExperienceModel) => {

    experience.trackables.forEach((value: UploadImageFileModel) => {
      if (value) {
        value.selectedFilDisplay = "";
      }
    });
    saveExperienceToLocalStore(experience);
  }

  const validateExperienceBeforePublish = () => {
    setErrorOccured(false);
    setErrorMessage("");
    if (experience) {
      const validationResult = ExperienceFileNameService.ValidateExperienceName(experience.title, originalExperienceName);

      if (validationResult.success) {

        const filesUploaded = checkForUploadingFiles(experience);


        if (filesUploaded.success) {
          removeLocalFileDisplayPath(experience);
          setVideoIndexTrackable();

          const emailValidation = ExperienceFileNameService.ValidateUserEmail(user.email);

          if (emailValidation.success) {



            setCreateExperienceStep('success');



          } else {
            setErrorOccured(true);
            setErrorMessage(emailValidation.message);
          }

        } else {
          setErrorOccured(true);
          let validationMessages = "";
          filesUploaded.messages.map((message: string) => {
            validationMessages += message + " ";
            return true;
          });

          setErrorMessage(validationMessages);
        }
      } else {
        setErrorOccured(true);
        setErrorMessage(validationResult.message);
      }
    }
  }

  const setVideoIndexTrackable = function () {
    let illustrationId = "";

    experience.videos.forEach((video, index) => {
      illustrationId = video.selectedTrackableId;

      if (illustrationId) {
        const illustration = experience.trackables.find(t => t.id === illustrationId);

        if (illustration) {
          illustration.video = index;
          illustration.videoId = video.id;
          saveExperienceToLocalStore(experience);
        }
      }
    });
  }

  const handleGoBack = () => {
    setErrorOccured(false);
    setVaidationMessage("");
    setErrorMessage("");
    setCreateExperienceStep('filters')
  }

  const checkoutAndPublish = () => {
    const experiencesStorage = localStorage.getItem(Constants.ExperienceListKey);
    const isEditingStorage = localStorage.getItem('isEditing');
    console.log('is editing', isEditingStorage)
    if (experiencesStorage !== null && experiencesStorage !== "") {
      const experienceStorageObj = JSON.parse(experiencesStorage);

      if (isEditingStorage === "true") {
        setCreateExperienceStep('success');
        return;
      }

      if (
        user.stripeRole === "basic" &&
        user.email !== "guest@email.com" &&
        experienceStorageObj.length < 5
      ) {
        setCreateExperienceStep('success');
        return;
      }

      if (
        user.stripeRole === "basic" &&
        user.email !== "guest@email.com" &&
        experienceStorageObj.length >= 5
      ) {
        setCreateExperienceStep('subscription');
        return;
      }
    }

    validateExperienceBeforePublish();

    if (continueAsGuest) {
      setCreateExperienceStep('subscription');
      return;
    }

    if (user.email === "guest@email.com") {
      setCreateExperienceStep('loggin');
      return;
    }

    if (!errorOccured && errorMessage) {
      setCreateExperienceStep('subscription');
    }
  }

  useEffect(() => {
    const updateIllustrationList = (model: ExperienceModel) => {

      const illustrationsCopy = Object.assign([], illustrations);
      model.trackables.map(function (value: UploadImageFileModel) {
        if (value) {
          illustrationsCopy.push(value);
        }

        return value;
      });

      setIllustrations(illustrationsCopy);
    }

    const updateVideoList = (model: ExperienceModel) => {

      const videosCopy = Object.assign([], videos);
      model.videos.map(function (value: UploadVideoFileModel) {
        if (value) {
          videosCopy.push(value);
        }

        return value;
      });

      setVideos(videosCopy);
    }
    if (!experienceLoaded) {
      setExperienceLoaded(true);
      setOriginalExperienceName(experience.title);
      setExperienceName(experience.title);
      updateIllustrationList(experience);
      updateVideoList(experience);
    }
  },
    [
      experienceLoaded,
      experience,
      illustrations,
      videos,
      setExperienceName,
      setOriginalExperienceName
    ]);


  useEffect(() => {
    if (!user) {
      const randomNumber = Math.floor(Math.random() * 10000000000000);

      const guestUser = new UserModel();

      guestUser.id = `Guest ${randomNumber}`
      guestUser.name = "Guest";
      guestUser.firstName = "Guest";
      guestUser.lastName = "NoUser";
      guestUser.email = "guest@gmail.com";
      guestUser.loginProvider = "None";
      guestUser.accessToken = `${randomNumber}`;
      guestUser.firebaseUser = null;
    }

    if (
      user?.email === "" ||
      user?.stripeRole !== "basic" ||
      user?.email === "guest@gmail.com"
    ) {
      experience.duration = 1;
      setExperience(experience);
    }
  }, [
    user,
    experience,
    setExperience
  ])

  useEffect(() => {
    let unmounted = false;
    const productsList = fetchProducts();
    Promise.resolve(productsList)
      .then((res) => {
        if (!unmounted) {
          setTimeout(() => {
            if (!isDevelopment()) {
              const filtered = res?.filter(product => product.description !== "TEST PRIME");
              console.log("filtered", filtered)
              if (user && user.stripeRole === "basic" && user.email !== "guest@email.com") {
                const filteredBasicUser = filtered?.filter(product => (
                  product.name === "Free" ||
                  product.name === "One time"

                ));
                setProducts(filteredBasicUser);
                localStorage.setItem('products', JSON.stringify(filteredBasicUser));
              }
              setProducts(filtered);
              localStorage.setItem('products', JSON.stringify(filtered));
            } else {
              if (user && user.stripeRole === "basic" && user.email !== "guest@email.com") {
                const filteredBasicUser = res?.filter(product => (
                  product.name === "Free" ||
                  product.name === "One time"
                ));
                setProducts(filteredBasicUser);
                localStorage.setItem('products', JSON.stringify(filteredBasicUser));
              } else {
                setProducts(res)
                localStorage.setItem('products', JSON.stringify(res));
              }
            }
          }, 2000)
        }
      }).catch((error: string) => {
        if (!unmounted) {
          console.log(error);
        }
      });
    return () => { unmounted = true };
  }, [user])

  // useEffect(() => {
  //   const qrCodeLocalStore = localStorage.getItem('qrCodeImg');
  //   if (qrCodeLocalStore) {
  //     setqrCodeImg(qrCodeLocalStore);
  //   }
  // }, [setqrCodeImg])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      localStorage.setItem('step', createExperienceStep);
    }

  }, [
    createExperienceStep
  ])

  useEffect(() => {
    if (experience && experience.title !== "") {
      setExperience(experience);
      setIllustrations(experience.trackables);
      const storageStep = localStorage.getItem('step');
      setExperienceName(experience.title);
      setVideos(experience.videos);

      if (storageStep !== null) {
        setCreateExperienceStep(storageStep);
      }
    }
    // eslint-disable-next-line
  }, [
    experience
  ])

  return (
    <>
      <div className="createExperienceContainer">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <header>
            <svg width="28" height="28" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7273 6.54545L15.75 4.29545L18 3.27273L15.75 2.25L14.7273 0L13.7045 2.25L11.4545 3.27273L13.7045 4.29545L14.7273 6.54545ZM8.59091 6.95455L6.54545 2.45455L4.5 6.95455L0 9L4.5 11.0455L6.54545 15.5455L8.59091 11.0455L13.0909 9L8.59091 6.95455ZM14.7273 11.4545L13.7045 13.7045L11.4545 14.7273L13.7045 15.75L14.7273 18L15.75 15.75L18 14.7273L15.75 13.7045L14.7273 11.4545Z" fill={iconColor} />
            </svg>

            <h2>Premium feature</h2>
          </header>

          <p>
            Want to enhance your experience? <br />
            Subscribe to have access to premium features.
          </p>

          <footer>
            <button onClick={closeModal}>CLOSE</button>
            <button onClick={() => {
              setCreateExperienceStep('subscription')
              setModalIsOpen(false)
            }}>SUBSCRIBE</button>
          </footer>
        </Modal>

        {createExperienceStep === 'target-image' && (
          <div className="targetImageContainer">
            <div className="targetImageInfo">

              <aside>
                <div className="imageTargetText">
                  <h2>What is a target image?</h2>
                  <p>
                    This is the foundation of your AR experience.
                    Anyone using your experience will be able to point
                    their camera at the target image, in order to activate
                    your augmented reality content
                    (videos, 360° videos, 360° images and 3D models).
                  </p>
                  <h3>What’s good to use as a target image?</h3>
                  <p>
                    Posters, promos, books, postcards, selfies - whatever you like!
                  </p>
                </div>
              </aside>
              <img src={imageTargetPng} alt="Target" />
            </div>

            <div className="targetImageDataContainer">

              <ExperienceStep />

              <ExperienceNameInput
                errorMessage={errorMessage}
                experienceName={experienceName}
                handleExperienceNameChange={handleExperienceNameChange}
              />

              <h2>Add a video</h2>
                
              <button className="videoTargetNameContainer" onClick={() => setCreateExperienceStep('filters')}>ADD</button>
                
              <h2>OR</h2>
              {illustrations.length === 0 || addingMoreTargetImages ? (
                <>
                  <h4>Now, time to upload your target image!</h4>
                  <p>This is the image that will be used to activate your experience on your smartphone’s camera. </p>
                  <h2>Upload your target image here:</h2>
                  <div className="targetImageFieldsContainer">
                    <UploadImage
                      setAddingMoreTargetImages={setAddingMoreTargetImages}
                      addIllustration={addIllustration}
                      createExperienceStep={createExperienceStep}
                      setCreateExperienceStep={setCreateExperienceStep}
                    />

                  </div>
                </>
              ) : null}

              {!addingMoreTargetImages && illustrations.length > 0 && (
                <>
                  <h4 className="feedbackTargetImage">You’ve added your target image - lovely choice! </h4>
                  <h4>Now, it’s time to add some filters. Click <strong>NEXT</strong> to proceed!</h4>
                </>
              )}

              {illustrations.length > 0 && (
                <>
                  <h2>Target-Images</h2>
                  {illustrations.map((value: UploadImageFileModel, index) => {
                    return (
                      <div key={index}>
                        <ImageFileComponent
                          uploadListOfImageTarget={uploadListOfImageTarget}
                          isStillUploading={isStillUploading}
                          setIsStillUploading={setIsStillUploading}
                          experience={experience}
                          uploadImage={value}
                          deleteIllustration={deleteIllustrationFromExperience}
                        />
                      </div>
                    )
                  })}


                  <div className="targetImageStepButtonsContainerNext">
                    <button onClick={() => setCreateExperienceStep('filters')}>NEXT</button>
                  </div>


                </>
              )}
            </div>

          </div>
        )}

        {createExperienceStep === 'filters' && (
          <div className="filtersContainer">

            <div className="filtersImageContainer">

              <aside>
                <div className="imageTargetText">
                  <h2>What is a filter?</h2>
                  <p>The content you choose as a filter will then be projected onto the target image you selected in the previous step. perkLAB will use it to create an immersive experience mixing the real world and your content.</p>
                </div>
              </aside>

              <img src={videoFilterPng} alt="AR Filter" />

            </div>

            <div className="filtersDataContainer">

              <ExperienceStep />

              <ExperienceNameInput
                errorMessage={errorMessage}
                experienceName={experienceName}
                handleExperienceNameChange={handleExperienceNameChange}
              />

              {videos.length === 0 || addingMoreFilters ? (
                <>
                  <h4>Choose which type of filter to upload <br /> (this is the content you want to see in AR)</h4>
                  <VideoUpload
                    user={user}
                    addVideo={addVideo}
                    illustrations={illustrations}
                    setUploadListOfFilters={setUploadListOfFilters}
                    setAddingMoreFilters={setAddingMoreFilters}
                    canActivateSound={canActivateSound}
                  />
                </>
              ) : null}

              {videos.length > 0 && (
                <>
                  {/* <h4>Review your filters<br /> You can choose to proceed, or add another: </h4> */}
                  <h4>Review your filter and<br /> you can proceed</h4>
                  <p>Filters list</p>

                  {videos.map((value: UploadVideoFileModel, index) => {
                    return (
                      <div className="filterListContainer" key={index}>
                        <VideoFileComponent
                          uploadedVideo={value}
                          illustrations={illustrations}
                          deleteVideo={deleteVideoFromExperience}
                          uploadListOfFilters={uploadListOfFilters}
                          setUploadListOfFilters={setUploadListOfFilters}
                          setIsFiltersStillUploading={setIsFiltersStillUploading}
                          experience={experience}
                        />
                      </div>
                    )
                  })}
                </>
              )}

              <div className="targetImageStepButtonsContainer">
                <button onClick={() => setCreateExperienceStep('target-image')}>BACK</button>
                {videos.length > 0 && (
                  <>
                    {/* {!has360Filter && (
                      <button onClick={handleAddMoreFilter}>ADD ANOTHER FILTER</button>
                    )} */}

                    <button onClick={() => setCreateExperienceStep('publish')}>NEXT</button>
                  </>
                )}
              </div>

            </div>

          </div>
        )}

        {createExperienceStep === 'publish' && (
          <div className="publishContainer">

            <img src={createAR} alt="AR" />

            <main>

              <ExperienceStep />

              <ExperienceNameInput
                errorMessage={errorMessage}
                experienceName={experienceName}
                handleExperienceNameChange={handleExperienceNameChange}
              />

              {(!errorOccured || validationError.length > 0) && (
                <>
                  {experience && experience.videos.length === 0 && (
                    <p>Please add a filter.</p>
                  )}

                  {(experience && experience.videos.length) > 0 && (
                    <>
                      {experienceName.length < 3 ? (
                        <h4 className="uploadingHeader">
                          Please enter an experience name with more than
                          <strong> 3 characters</strong>.
                        </h4>
                      ) : (
                        <>
                          <h4>
                            <strong>Good news - </strong>
                            your experience is ready to be published!
                          </h4>

                        </>
                      )}

                      <div className="pubishOption" >
                        {(isStillUploading || isFiltersStillUploading || experienceName.length < 3) || savingExperience ? (
                          <Spinner animation="border" role="status" className="publishSpinner">
                            <span className="sr-only">Uploading</span>
                          </Spinner>
                        ) : (
                          // <div className="pubishButton" onClick={saveExperience}>
                          <button
                            className={`pubishButton`}
                            // className={`pubishButton ${listOfExperiences.length > 5 && "disabled"}`} 
                            onClick={checkoutAndPublish}
                          // disabled={listOfExperiences.length > 5}
                          >
                            <svg width="35" height="35" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M41.8251 0.38763C33.5149 0.238479 24.0375 4.58694 18.0385 11.3325C12.3056 11.4408 6.7235 13.7935 2.61361 17.9075C2.37415 18.1437 2.28901 18.4971 2.39366 18.8167C2.50009 19.138 2.7768 19.3706 3.11028 19.4186L9.94649 20.3987L9.10216 21.3451C8.7882 21.6967 8.80416 22.2312 9.13763 22.565L20.5378 33.9768C20.7099 34.149 20.9369 34.236 21.1657 34.236C21.3768 34.236 21.5879 34.1615 21.7564 34.0105L22.7019 33.1653L23.681 40.0085C23.7289 40.3423 23.9914 40.5874 24.3089 40.6939C24.3905 40.7206 24.4756 40.733 24.5626 40.733C24.8144 40.733 25.0716 40.6229 25.249 40.4471C29.3021 36.3898 31.6524 30.802 31.7606 25.0632C38.5064 19.0457 42.8859 9.56219 42.6925 1.25413C42.6801 0.781815 42.2987 0.40006 41.8251 0.38763V0.38763ZM33.6515 15.7147C32.7877 16.5794 31.6524 17.0126 30.5154 17.0126C29.3784 17.0126 28.2432 16.5794 27.3794 15.7147C25.6517 13.9835 25.6517 11.1673 27.3794 9.43613C29.1088 7.70491 31.922 7.70491 33.6515 9.43613C35.3809 11.1673 35.3809 13.9852 33.6515 15.7147Z" fill={iconColor} />
                              <path d="M5.1434 30.2233C3.25428 32.1125 0.66071 40.6476 0.371162 41.6134C0.277589 41.9242 0.3641 42.2614 0.591854 42.4909C0.761345 42.6604 0.985568 42.7505 1.21685 42.7505C1.3016 42.7505 1.38634 42.7381 1.47109 42.7134C2.43684 42.4238 10.9714 39.8301 12.8606 37.9409C14.988 35.8133 14.988 32.3509 12.8606 30.2233C10.7313 28.0957 7.27087 28.0975 5.1434 30.2233V30.2233Z" fill={iconColor} />
                            </svg>
                            <p>Publish</p>
                          </button>
                        )}
                      </div>

                      <p>Keep editing</p>

                      <div className="publishButtonsContainer">

                        {/* <button
                          className={
                            `pubishOption ${(
                              user.stripeRole === undefined ||
                              illustrations.length === 8) ||
                              user.email === "guest@email.com"
                              ? "disabled"
                              : ""}
                            `}
                          onClick={handleAddMoreTargetImage}
                          disabled={illustrations.length === 8}>
                          <svg
                            height="35"
                            viewBox="0 0 30 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                           <path d="M7.92811 21.214C6.05054 19.3398 4.99545 16.7959 4.99545 14.143C4.99545 11.4901 6.05054 8.94609 7.92811 7.0719L8.06953 6.93048C10.9613 4.15438 15.2309 3.37228 18.9189 4.9431C22.6069 6.51391 25.0013 10.1344 25.0033 14.143C25.005 18.1894 22.5679 21.8381 18.8294 23.3862C15.0908 24.9344 10.7877 24.077 7.92811 21.214ZM14.0022 15.14V18.8028H15.9962V15.14H19.659V13.1459H15.9962L15.9962 9.48313H14.0022L14.0022 13.1459L10.3393 13.1459V15.14L14.0015 15.1407L14.0022 15.14Z" fill={iconColor} />

                          </svg>
                          <p>Add more Target-Images</p>

                          {user.stripeRole === undefined && (
                            <svg
                              className="premiumIcon"
                              width="25"
                              height="25"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M16 7.49642L14.2255 5.50406L14.4727 2.8667L11.8473 2.27903L10.4727 0L8 1.04634L5.52727 0L4.15273 2.27903L1.52727 2.85953L1.77455 5.49689L0 7.49642L1.77455 9.48877L1.52727 12.1333L4.15273 12.721L5.52727 15L8 13.9465L10.4727 14.9928L11.8473 12.7138L14.4727 12.1261L14.2255 9.48877L16 7.49642ZM8.72727 11.0798H7.27273V9.64644H8.72727V11.0798ZM8.72727 8.21309H7.27273V3.91304H8.72727V8.21309Z" fill="#BBA934" />
                            </svg>
                          )}

                        </button> */}

                        <div
                          className="pubishOption"
                          onClick={() => setCreateExperienceStep('personalize')}
                          >
                          <svg
                            width="35"
                            height="35"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                          <path d="M18 8L19.25 5.25L22 4L19.25 2.75L18 0L16.75 2.75L14 4L16.75 5.25L18 8ZM10.5 8.5L8 3L5.5 8.5L0 11L5.5 13.5L8 19L10.5 13.5L16 11L10.5 8.5ZM18 14L16.75 16.75L14 18L16.75 19.25L18 22L19.25 19.25L22 18L19.25 16.75L18 14Z" fill={iconColor} />

                          </svg>

                          <p>Personalize</p>
                        </div>

                      </div>
                    </>
                  )}
                </>
              )}

              {(isStillUploading || isFiltersStillUploading || experienceName.length < 3) || savingExperience ? (
                <p className="errorLoading">
                  Depending on the video size the upload and preparation of your experience might need 5 minutes or longer.
                  If it takes longer than 5 minutes, and you confirmed that the video is already uploaded, please
                  <a href="mailto: info@tripod-digital.co.nz">contact us.</a>
                </p>
              ) : null}

              {savingExperienceSuccess ? (
                <p>Experience saved successfully</p>
              ) : (null)}

              {(errorOccured || validationError.length > 0) && (
                <svg width="185" height="185" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M41.2656 0.916951C32.4235 9.44731 27.0625 23.8988 27.8428 37.2199C21.9637 43.3255 18.5881 51.6193 18.5925 60.2154C18.5891 60.7126 18.8694 61.1709 19.3129 61.3956C19.76 61.6202 20.2924 61.5741 20.6911 61.2757L28.8611 55.1546L28.9678 57.0264C29.0071 57.722 29.5824 58.264 30.2799 58.2643L54.1241 58.2765C54.484 58.2767 54.8122 58.1303 55.0514 57.8912C55.2721 57.6705 55.4147 57.3719 55.4331 57.038L55.5379 55.1664L63.7142 61.2958C64.1131 61.5947 64.6437 61.5764 65.0869 61.3559C65.2 61.2984 65.302 61.2224 65.3929 61.1316C65.6561 60.8683 65.8099 60.4844 65.8116 60.1152C65.8072 51.6379 62.4232 43.3406 56.5379 37.229C57.299 23.8882 51.9641 9.39792 43.078 0.916021C42.5713 0.435316 41.7737 0.43491 41.2656 0.916951V0.916951ZM48.7427 25.481C48.7436 27.2878 48.0099 28.9272 46.8214 30.1157C45.633 31.3041 43.9935 32.0379 42.1868 32.037C38.5714 32.0333 35.6278 29.0897 35.6241 25.4743C35.6223 21.8571 38.5628 18.9165 42.1801 18.9184C45.7973 18.9202 48.7427 21.8656 48.7427 25.481Z" fill="#A5A5B0" />
                  <rect x="40.4277" y="49.9246" width="2.95641" height="11.8256" fill="#A5A5B0" />
                  <line x1="1" y1="61.4055" x2="83" y2="61.4055" stroke="#A5A5B0" strokeWidth="1.18903" />
                </svg>
              )}

              {errorOccured ? (
                <p>Error: there was a problem saving your experience</p>
              ) : (null)}

              {savingExperienceSuccess ? (
                <button onClick={preview} style={{ float: 'left', width: '160px' }}>Preview</button>
              ) : (null)}

              {illustrations.length === 8 && (
                <p className="maximumOfExperiences">You reached the maximum of Target-Images. <br />
                  <a className="maximumOfExperiencesMail" href="mailto:info@tripod-digital.co.nz">Please contact us.</a></p>
              )}

              <div className="targetImageStepButtonsContainer">
                <button onClick={handleGoBack}>BACK</button>
              </div>

              {user.stripeRole === undefined && (
                <div className="personalizeFooter" onClick={() => setCreateExperienceStep('subscription')}>
                  <svg width="25" height="25" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 7.49642L14.2255 5.50406L14.4727 2.8667L11.8473 2.27903L10.4727 0L8 1.04634L5.52727 0L4.15273 2.27903L1.52727 2.85953L1.77455 5.49689L0 7.49642L1.77455 9.48877L1.52727 12.1333L4.15273 12.721L5.52727 15L8 13.9465L10.4727 14.9928L11.8473 12.7138L14.4727 12.1261L14.2255 9.48877L16 7.49642ZM8.72727 11.0798H7.27273V9.64644H8.72727V11.0798ZM8.72727 8.21309H7.27273V3.91304H8.72727V8.21309Z" fill="#BBA934" />
                  </svg>
                  <p>Premium feature, <strong>Subscribe now to try!</strong></p>
                </div>
              )}
            </main>
          </div>
        )}

        {createExperienceStep === 'success' && (
          <div className="createExperienceSuccessContainer">
            <ExperienceStep />
            <Success user={user} setUserModels={() => { }} experience={experience} />
          </div>
        )}

        {createExperienceStep === 'personalize' && (
          <div className="logoAndDatesContainer">
            <img src={createAR} alt="AR" />

            <div className="logoAndColorContainer">

              <ExperienceStep />

              <ExperienceNameInput
                errorMessage={errorMessage}
                experienceName={experienceName}
                handleExperienceNameChange={handleExperienceNameChange}
              />

              <ExperienceDetailsComponent
                user={user}
                experienceModel={experience}
                createExperienceStep={createExperienceStep}
                setCreateExperienceStep={setCreateExperienceStep}
              />
            </div>
          </div>
        )}

        {createExperienceStep === 'subscription' && (
          <SubscriptionPage
            user={user}
            products={products}
          />
        )}

        {createExperienceStep === 'loggin' && (
          <LogginPage user={user} handleContinueAsGuest={handleContinueAsGuest} />
        )}
      </div>
    </>
  )
}

export default CreateExperience;
