import { useEffect, useState } from "react"
import { db } from "./firebase";

interface Product {
  active: boolean,
  description: string,
  images: any[],
  name: string,
  role: any,
  price: Price,
  id: string,
  metadata: {features?: string[]},
}

interface Price {
  id: string,
  active: boolean,
  billing_scheme: string,
  currency: string,
  description: string,
  interval: string,
  interval_count: number,
  recurring: {interval_count: number, usage_type: string, interval: string, trial_period_days: number | null, aggregate_usage: number | null},
  tiers: any,
  tiers_mode: any,
  transform_quantity: any,
  trial_period_days: number,
  type: string,
  unit_amount: number,
}

const useProductsList = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(
    () => {
      db.collection('products')
        .where('active', '==', true)
        .get()
        .then(function (querySnapshot) {
          const _products: Product[] = [];
          querySnapshot.forEach(async function (productDoc) {
            // console.log(productDoc.id, ' => ', productDoc.data());
            // setProducts();
            const priceSnap = await productDoc.ref.collection('prices').get();
            priceSnap.docs.forEach((priceDoc) => {
              const product = Object.assign({}, productDoc.data(), { id: productDoc.id });
              product.price = Object.assign({}, priceDoc.data(), { id: priceDoc.id });
              _products.push(product as any);
            });
          });
          setProducts(_products);
        });
    },[])

  return products
}

export default useProductsList;