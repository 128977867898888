import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

import { Icon } from '@iconify/react';
import qrCode24Filled from '@iconify-icons/fluent/qr-code-24-filled';
import getPreviewUrl, { getStorageUrl } from 'src/services/config-reader';




interface IProps {
  expId: string,
  email?: string
}

const QRCodeComponent: React.FC<IProps> = ({ expId }) => {
  const [qrCodeImg, setqrCodeImg] = useState<string>();

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {

    // TODO
    if (!expId) return;
    QRCode.toDataURL(`${getPreviewUrl()}/?exp=${getStorageUrl()}${process.env.REACT_APP_Experience_Store}/${expId}`).then((qrCodeResult) => {
      setqrCodeImg(qrCodeResult);
    });
    console.log('QR URL', `${getPreviewUrl()}/?exp=${getStorageUrl()}${process.env.REACT_APP_Experience_Store}/${expId}`)
    }
    return () => { unmounted = true };
  }, [expId])



  return (
    <div style={{ height: "42px", width: "42px", maxHeight: "unset", maxWidth: "unset", marginLeft: "18px" }}>
      <a href={qrCodeImg} download>
        <Icon icon={qrCode24Filled} style={{ height: "42px", width: "42px", color: "var(--grey-dark)" }} />
      </a>
    </div>
  )
}

export default QRCodeComponent;
