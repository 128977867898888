import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../file-upload-button/FileUploadButton.css'
import { Spinner } from "react-bootstrap";
import UploadVideoFileModel from "src/models/UploadVideoFileModel";

import uploadVideoToCloudinary from '../../services/video-upload.service';
import DropDownComponent from "../drop-down-target-image/drop-down-component";
import UploadImageFileModel from "src/models/UploadImageFileModel";
// import { VideoPopupView } from "./VideoPopupView.";

// import standardPicture from '../../assets/images/picture.png';

import './styles/video-file-component.css'
import { ExperienceContext } from "src/contexts/ExperienceContext";
import ExperienceModel from "src/models/ExperienceModel";
import { iconColor } from "src/config";

interface IProps {
  uploadListOfFilters: boolean;
  setUploadListOfFilters: (state: boolean) => void;
  setIsFiltersStillUploading: (state: boolean) => void;
  uploadedVideo: UploadVideoFileModel;
  illustrations: Array<UploadImageFileModel>;
  deleteVideo: (UploadVideoFileModel: UploadVideoFileModel) => void;
  experience: ExperienceModel
}

const VideoFileComponent: React.FC<IProps> = (
  {
    deleteVideo,
    uploadedVideo,
    illustrations,
    uploadListOfFilters,
    setUploadListOfFilters,
    setIsFiltersStillUploading,
    experience
  }) => {

  const { saveExperienceToLocalStore, reloadList, setReloadList } = useContext(ExperienceContext);

  const [videoUploadedAttempted, setVideoUploadedAttempted] = useState(false);
  const [videoFileUploadeError, setVideoFileUploadedError] = useState(false);
  const [videoFileUploadedSuccess, setVideoFileUploadedSuccess] = useState(false);
  const [videofileUploadInProgress, setVideofileUploadInProgress] = useState(false);
  const [videoFileUploadedMessage, setVideoFileUploadedMessage] = useState("");
  const [selectedIllustration, setSelectedIllustration] = useState("");

  const [showPreview, setShowPreview] = useState(false);

  const [change, setChange] = useState(false);

  function handleSwitchLoops() {
    uploadedVideo.loops = !uploadedVideo.loops;
    saveExperienceToLocalStore(experience);
    setChange(!change);
  }
  function handleSwitchMute() {

    if (experience.videos.length === 1) {
      uploadedVideo.muted = !uploadedVideo.muted;
    } else {

      const filteredVideos = experience.videos.filter(video => {
        return video.id !== uploadedVideo.id
      })
      filteredVideos.forEach(video => video.muted = true);

      uploadedVideo.muted = !uploadedVideo.muted;
      setReloadList(!reloadList)
    }

    saveExperienceToLocalStore(experience);
    setChange(!change);
  }

  function handleSwitchStayVisible() {
    uploadedVideo.staysVisible = !uploadedVideo.staysVisible;
    saveExperienceToLocalStore(experience);
    setChange(!change);
  }
  function handleChangeDegree(e: React.ChangeEvent<HTMLInputElement>) {
    uploadedVideo.rotation = Number(e.target.value);
    saveExperienceToLocalStore(experience);
    setChange(!change);
  }
  function handleSwitchTransparentColor() {
    uploadedVideo.transparentColor = !uploadedVideo.transparentColor;
    setChange(!change);
  }
  function handleSwitchTransparentColorCode(color: string) {
    uploadedVideo.transparentColorCode = color;
    setChange(!change);
  }


  // const [showTargetImageOptions, setShowTargetImageOptions] = useState(false);

  useEffect(() => {
    if (!uploadListOfFilters) {
      const handleVideoFileUploadComplete = (
        success: boolean,
        message: string,
        url: string) => {

        if (success) {
          setVideoFileUploadedSuccess(true);
          setUploadListOfFilters(true);
          setVideoFileUploadedMessage(message);

          uploadedVideo.url = url;
          uploadedVideo.uploadedSuccessfully = true;
          uploadedVideo.stillUploading = false;
          setIsFiltersStillUploading(false);
          setVideoFileUploadedError(false);
          saveExperienceToLocalStore(experience);
        } else {
          uploadedVideo.uploadedError = true;
          setVideoFileUploadedError(true);
          setVideoFileUploadedMessage(message);
        }
      }

      if (uploadedVideo.videoFile instanceof Blob) {
        if (!videoUploadedAttempted) {
          if (uploadedVideo.videoFile) {
            setVideoUploadedAttempted(true);
            uploadedVideo.stillUploading = true;
            setIsFiltersStillUploading(true);

            (async () => {
              uploadVideoToCloudinary(
                uploadedVideo.videoFile,
                setVideofileUploadInProgress,
                handleVideoFileUploadComplete, uploadedVideo.filterType
              );
            })();
          }
        }
      } else {
        // Retrieve video from Cloudinary based on url
        if (uploadedVideo.url) {
          (async () => {
            const resourceResponse = await fetch(uploadedVideo.url);
            if (resourceResponse) {
              if (resourceResponse.status === 200) {
                const blob = await resourceResponse.blob();
                const src = window.URL.createObjectURL(blob);
                uploadedVideo.videoFileDisplay = src;

                if (uploadedVideo.selectedTrackableId && uploadedVideo.selectedTrackableId.length > 0) {
                  setSelectedIllustration(uploadedVideo.selectedTrackableId);
                }

                setVideoFileUploadedSuccess(true);
                setIsFiltersStillUploading(false);
                setUploadListOfFilters(true);
                saveExperienceToLocalStore(experience);
              }
            }
          })();
        }
      }
    }
  }, [
    videofileUploadInProgress,
    videoUploadedAttempted,
    uploadListOfFilters,
    setUploadListOfFilters,
    uploadedVideo,
    setIsFiltersStillUploading,
    saveExperienceToLocalStore,
    experience
    // setIsStillUploading
  ])

  const handleIllustrationSelected = (id: string) => {
    const selectedIllustrationModel = illustrations.find(o => o.id === id);

    if (selectedIllustrationModel) {
      uploadedVideo.selectedTrackableId = id
      setSelectedIllustration(id);
      saveExperienceToLocalStore(experience);
    }
  }

  const handleConfirmCallback = () => {
    deleteVideo(uploadedVideo);
    saveExperienceToLocalStore(experience);

  }

  return (
    <div className="videoFilterCardContainer">
      <div className={`videoFilterCardHeader ${illustrations.length === 0 ? 'empty' : ''}`}>
        <h3>{uploadedVideo.videoFileName} <span>{uploadedVideo.filterType && ` - ${uploadedVideo.filterType}`}</span></h3>
        <button
          onClick={() => {
            if (window.confirm('Are you sure you wish to delete this item?'))
              handleConfirmCallback()

          }}
        >
          X
        </button>
      </div>

      {illustrations.length > 0 && (
        <>
        <p>The target-image below will display this filter once the picture is recognized by your camera.</p>
          <div className="videoFilterCardTargetImageSelector">
            <DropDownComponent
              illustrations={illustrations}
              selectedOptionId={uploadedVideo.selectedTrackableId}
              setSelectedOption={handleIllustrationSelected}
            />
          </div>
        </>
      )}


      <div className="videoFilterCardSetup">

        <div className="videoFilterCardSetupItemsContainer">

          {uploadedVideo.filterType && uploadedVideo.filterType.includes("Video") && (
            <>
              <div className={`videoFilterCardSetupItem ${uploadedVideo.loops && "active"}`} onClick={handleSwitchLoops}>
                {uploadedVideo.loops ? (
                  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.87181 3.81264V0.96106L4.06969 4.76317L7.87181 8.56529V5.7137C11.0181 5.7137 13.575 8.27062 13.575 11.4169C13.575 12.3769 13.3373 13.2894 12.9096 14.0784L14.2974 15.4661C15.0388 14.297 15.476 12.9092 15.476 11.4169C15.476 7.21554 12.0731 3.81264 7.87181 3.81264ZM7.87181 17.12C4.72556 17.12 2.16863 14.5631 2.16863 11.4169C2.16863 10.4568 2.40627 9.54433 2.834 8.75539L1.44623 7.36762C0.704821 8.53677 0.267578 9.92454 0.267578 11.4169C0.267578 15.6182 3.67047 19.0211 7.87181 19.0211V21.8727L11.6739 18.0706L7.87181 14.2685V17.12Z" fill={uploadedVideo.loops ? "#fff" : iconColor} />
                  </svg>
                ) : (
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 2L19 20" stroke="#A5A5B0" strokeWidth="2" />
                    <path d="M10.6264 2.76616V0L6.93821 3.68821L10.6264 7.37641V4.61026C13.6784 4.61026 16.1587 7.09058 16.1587 10.1426C16.1587 11.0738 15.9282 11.959 15.5133 12.7243L16.8595 14.0705C17.5787 12.9364 18.0028 11.5902 18.0028 10.1426C18.0028 6.0671 14.7019 2.76616 10.6264 2.76616ZM10.6264 15.6749C7.57442 15.6749 5.0941 13.1946 5.0941 10.1426C5.0941 9.2113 5.32462 8.32613 5.73954 7.56082L4.39334 6.21463C3.67414 7.34875 3.25 8.69495 3.25 10.1426C3.25 14.218 6.55095 17.519 10.6264 17.519V20.2851L14.3146 16.5969L10.6264 12.9087V15.6749Z" fill="#A5A5B0" />
                  </svg>
                )}

              </div>
              <div className={`videoFilterCardSetupItem ${!uploadedVideo.muted && "active"}`} onClick={handleSwitchMute}>
                {uploadedVideo.muted ?
                  (
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.2521 9.36744C13.2521 7.685 12.2825 6.2402 10.8757 5.53681V7.63748L13.2045 9.96627C13.233 9.77617 13.2521 9.57656 13.2521 9.36744V9.36744ZM15.6284 9.36744C15.6284 10.2609 15.4383 11.0974 15.1151 11.8768L16.5504 13.3121C17.1777 12.1335 17.5294 10.7932 17.5294 9.36744C17.5294 5.29918 14.6874 1.89629 10.8757 1.0313V2.98939C13.6228 3.80685 15.6284 6.35426 15.6284 9.36744ZM1.62709 0.812683L0.419922 2.01985L4.91592 6.51585H0.419922V12.219H4.22204L8.97468 16.9717V10.5746L13.0144 14.6144C12.3776 15.1086 11.6647 15.4983 10.8757 15.736V17.6941C12.1875 17.3994 13.3756 16.7911 14.3832 15.9736L16.3223 17.9222L17.5294 16.715L8.97468 8.16027L1.62709 0.812683ZM8.97468 1.76321L6.98807 3.74982L8.97468 5.73642V1.76321Z" fill="#A5A5B0" />
                    </svg>
                  ) : (
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 5.77V11.77H4L9 16.77V0.77L4 5.77H0ZM13.5 8.77C13.5 7 12.48 5.48 11 4.74V12.79C12.48 12.06 13.5 10.54 13.5 8.77ZM11 0V2.06C13.89 2.92 16 5.6 16 8.77C16 11.94 13.89 14.62 11 15.48V17.54C15.01 16.63 18 13.05 18 8.77C18 4.49 15.01 0.91 11 0V0Z" fill="#fff" />
                    </svg>
                  )}
              </div>

              <div className={`videoFilterCardSetupItem ${uploadedVideo.transparentColor && "active"}`} onClick={handleSwitchTransparentColor}>
                {uploadedVideo.transparentColor ? (
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ zIndex: 5 }}>
                                        <path d="M9 0C4.03 0 0 4.03 0 9C0 13.97 4.03 18 9 18C9.83 18 10.5 17.33 10.5 16.5C10.5 16.11 10.35 15.76 10.11 15.49C9.88 15.23 9.73 14.88 9.73 14.5C9.73 13.67 10.4 13 11.23 13H13C15.76 13 18 10.76 18 8C18 3.58 13.97 0 9 0ZM3.5 9C2.67 9 2 8.33 2 7.5C2 6.67 2.67 6 3.5 6C4.33 6 5 6.67 5 7.5C5 8.33 4.33 9 3.5 9ZM6.5 5C5.67 5 5 4.33 5 3.5C5 2.67 5.67 2 6.5 2C7.33 2 8 2.67 8 3.5C8 4.33 7.33 5 6.5 5ZM11.5 5C10.67 5 10 4.33 10 3.5C10 2.67 10.67 2 11.5 2C12.33 2 13 2.67 13 3.5C13 4.33 12.33 5 11.5 5ZM14.5 9C13.67 9 13 8.33 13 7.5C13 6.67 13.67 6 14.5 6C15.33 6 16 6.67 16 7.5C16 8.33 15.33 9 14.5 9Z" fill={uploadedVideo.transparentColor ? "#fff" : iconColor}/>
                  </svg>
                ) : (
                  <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 3C6.58222 3 3 6.58222 3 11C3 15.4178 6.58222 19 11 19C11.7378 19 12.3333 18.4044 12.3333 17.6667C12.3333 17.32 12.2 17.0089 11.9867 16.7689C11.7822 16.5378 11.6489 16.2267 11.6489 15.8889C11.6489 15.1511 12.2444 14.5556 12.9822 14.5556H14.5556C17.0089 14.5556 19 12.5644 19 10.1111C19 6.18222 15.4178 3 11 3ZM6.11111 11C5.37333 11 4.77778 10.4044 4.77778 9.66667C4.77778 8.92889 5.37333 8.33333 6.11111 8.33333C6.84889 8.33333 7.44444 8.92889 7.44444 9.66667C7.44444 10.4044 6.84889 11 6.11111 11ZM8.77778 7.44444C8.04 7.44444 7.44444 6.84889 7.44444 6.11111C7.44444 5.37333 8.04 4.77778 8.77778 4.77778C9.51556 4.77778 10.1111 5.37333 10.1111 6.11111C10.1111 6.84889 9.51556 7.44444 8.77778 7.44444ZM13.2222 7.44444C12.4844 7.44444 11.8889 6.84889 11.8889 6.11111C11.8889 5.37333 12.4844 4.77778 13.2222 4.77778C13.96 4.77778 14.5556 5.37333 14.5556 6.11111C14.5556 6.84889 13.96 7.44444 13.2222 7.44444ZM15.8889 11C15.1511 11 14.5556 10.4044 14.5556 9.66667C14.5556 8.92889 15.1511 8.33333 15.8889 8.33333C16.6267 8.33333 17.2222 8.92889 17.2222 9.66667C17.2222 10.4044 16.6267 11 15.8889 11Z" fill="#A5A5B0" />
                    <path d="M1 2L19 20" stroke="#A5A5B0" strokeWidth="2" />
                    <path d="M2 1L20 19" stroke="#090C08" strokeWidth="2" />
                  </svg>
                )}

                {uploadedVideo.transparentColor && (
                  <span style={{ backgroundColor: uploadedVideo.transparentColorCode }}></span>
                )}

              </div>

              {uploadedVideo.transparentColor && (
                <input
                  className="videoFilterCardSetupItem"
                  aria-label="color"
                  type="color"
                  value={uploadedVideo.transparentColorCode}
                  onChange={e => handleSwitchTransparentColorCode(e.target.value)}
                />
              )}
            </>
          )}

          {!uploadedVideo.three60 && (
            <div className={`videoFilterCardSetupItem ${uploadedVideo.staysVisible && "active"}`} onClick={handleSwitchStayVisible}>
              {uploadedVideo.staysVisible ? (
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.0769 0.763184C6.32426 0.763184 2.26551 3.71933 0.621094 7.89215C2.26551 12.065 6.32426 15.0211 11.0769 15.0211C15.8295 15.0211 19.8883 12.065 21.5327 7.89215C19.8883 3.71933 15.8295 0.763184 11.0769 0.763184ZM11.0769 12.6448C8.45345 12.6448 6.32426 10.5156 6.32426 7.89215C6.32426 5.26869 8.45345 3.1395 11.0769 3.1395C13.7004 3.1395 15.8295 5.26869 15.8295 7.89215C15.8295 10.5156 13.7004 12.6448 11.0769 12.6448ZM11.0769 5.04056C9.49903 5.04056 8.22532 6.31427 8.22532 7.89215C8.22532 9.47002 9.49903 10.7437 11.0769 10.7437C12.6548 10.7437 13.9285 9.47002 13.9285 7.89215C13.9285 6.31427 12.6548 5.04056 11.0769 5.04056Z" fill={uploadedVideo.staysVisible ? "#fff" : iconColor} />
                </svg>
              ) : (
                <svg width="22" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.50432 3.36842C11.889 3.36842 13.8245 5.25474 13.8245 7.57895C13.8245 8.12632 13.7121 8.64 13.5134 9.12L16.0364 11.5789C17.3411 10.5179 18.3693 9.14526 19 7.57895C17.5052 3.88211 13.8158 1.26316 9.49568 1.26316C8.28604 1.26316 7.12824 1.47368 6.05684 1.85263L7.92315 3.67158C8.41564 3.47789 8.9427 3.36842 9.50432 3.36842ZM0.864029 1.06947L2.83402 2.98947L3.23147 3.37684C1.79718 4.46316 0.673943 5.91158 0 7.57895C1.49477 11.2758 5.18417 13.8947 9.50432 13.8947C10.8436 13.8947 12.1223 13.6421 13.2888 13.1874L13.6517 13.5411L16.1833 16L17.2806 14.9305L1.96135 0L0.864029 1.06947ZM5.64211 5.72632L6.98136 7.03158C6.93815 7.20842 6.91223 7.39368 6.91223 7.57895C6.91223 8.97684 8.07003 10.1053 9.50432 10.1053C9.69441 10.1053 9.88449 10.08 10.0659 10.0379L11.4052 11.3432C10.8263 11.6211 10.1869 11.7895 9.50432 11.7895C7.1196 11.7895 5.18417 9.90316 5.18417 7.57895C5.18417 6.91368 5.35698 6.29053 5.64211 5.72632V5.72632ZM9.36608 5.06947L12.0878 7.72211L12.105 7.58737C12.105 6.18947 10.9472 5.06105 9.51296 5.06105L9.36608 5.06947Z" fill="#A5A5B0" />
                </svg>
              )}
            </div>
          )}

          {uploadedVideo.three60 && (
            <div className={`videoFilterCardDegreeItem ${uploadedVideo.three60 && "active"}`}>
              <input
                className="videoFilterCardInput"
                type="text"
                pattern='[0-9]{0,5}'
                maxLength={3}
                value={uploadedVideo.rotation === null ? "" : uploadedVideo.rotation}
                onChange={(e) => handleChangeDegree(e)}
              />
              <span>o</span>
            </div>
          )}

        </div>

        <button className="mainPreviewButton" onClick={() => setShowPreview(!showPreview)}>
          {videofileUploadInProgress ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">Uploading</span>
            </Spinner>) :
            (videoFileUploadeError && (
              { error: videoFileUploadedMessage }
            ))}

          {!videofileUploadInProgress && (
            <p>FILTER VIEW</p>
          )}
        </button>

        {showPreview && (
          <div className="previewContainer">
            <div className="previewContainerHeader">
              <h4>Filter Preview</h4>
              <div className="cardPreviewButton" onClick={() => setShowPreview(false)}>X</div>
            </div>

            <div className="videoContainer">
              {uploadedVideo.filterType && uploadedVideo.filterType.includes("Video") && (
                <video
                  playsInline
                  controls id="videoStar"
                  src={uploadedVideo.videoFileDisplay}
                // src={uploadedVideo.url !== "" ? `${uploadedVideo.url}`  : uploadedVideo.videoFileDisplay}
                >
                </video>
              )}

              {uploadedVideo.filterType && uploadedVideo.filterType.includes("Image") && (
                <img
                  src={uploadedVideo.videoFileDisplay}
                  alt="Filter"
                >
                </img>
              )}

            </div>
          </div>
        )}

        <div style={{ display: 'none' }}>{videoFileUploadedSuccess.valueOf()}{selectedIllustration} </div>

      </div>
    </div>




  );
}

export default VideoFileComponent;