import { v4 as uuidv4 } from 'uuid';
import { storage } from '../services/firebase';
import firebase from 'firebase/app';
import isDevelopment from 'src/services/utilities';

const uploadVideo = function (
  file: any,
  setVideofileUploadInProgress: any,
  handleVideoFileUploadComplete: any
) {
  let fileNameUnique = '';

  if (isDevelopment()) {
    fileNameUnique = 'development.video-' + uuidv4();
  } else {
    fileNameUnique = 'video-' + uuidv4();
  }

  setVideofileUploadInProgress(true);

  const uploadTask = storage
    .ref()
    .child('videos/' + fileNameUnique)
    .put(file);

  const updateCallback = (success: boolean, message: string, url: string) => {
    setVideofileUploadInProgress(false);
    handleVideoFileUploadComplete(success, message, url);
  };

  uploadTask
    .then(function (snapshot: any) {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;

        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;

        case firebase.storage.TaskState.SUCCESS:
          console.log('Success');
          snapshot.ref.getDownloadURL().then(function (downloadURL: string) {
            console.log('File available at', downloadURL);
            updateCallback(
              true,
              'Video file uploaded successfuly',
              downloadURL
            );
          });
          break;
      }
    })
    .catch((error: any) => {
      console.log(error);
      updateCallback(false, 'There was an error uploading video file', '');
    });
};

const uploadVideoToCloudinary = async function (
  file: any,
  setVideofileUploadInProgress: any,
  handleVideoFileUploadComplete: any,
  fileType: 'Video' | '360° Image' | '360° Video' | '' | '3D Object' = 'Video'
) {
  let fileNameUnique = '';

  if (isDevelopment()) {
    fileNameUnique = 'development.video-' + uuidv4();
  } else {
    fileNameUnique = 'video-' + uuidv4();
  }

  //"https://api.cloudinary.com/v1_1/<PUT-YOUR-CLOUD-NAME-HERE>/image/upload");

  const url = 'https://api.cloudinary.com/v1_1/tripod-digital/upload';
  const formData = new FormData();
  if (fileType === '360° Image') {
    formData.append('upload_preset', 'mixr360Image');
  } else if (fileType === '360° Video') {
    formData.append('upload_preset', 'mixr360');
  } else {
    // asyncPath = "video"
    formData.append('upload_preset', 'c8nrutmr');
  }

  formData.append('file', file, fileNameUnique);
  formData.append('public_id', fileNameUnique);

  setVideofileUploadInProgress(true);

  fetch(url, {
    method: 'POST',
    body: formData
  })
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      setVideofileUploadInProgress(false);
      handleVideoFileUploadComplete(
        true,
        'Video file uploaded successfuly',
        result.secure_url
          ? result.secure_url
          : `https://res.cloudinary.com/tripod-digital/video/upload/v1612744187/${result.public_id}.mp4`
      );
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
      setVideofileUploadInProgress(false);
      handleVideoFileUploadComplete(
        false,
        'There was a problem uploading the video',
        ''
      );
    });
};
export { uploadVideo, uploadVideoToCloudinary as default };
