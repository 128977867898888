import React from 'react'
import LogginPage from '../loggin-page'

export default function LogginPageMain() {
    return (
        <div className="createExperienceContainer">
            <LogginPage origin="/login" />            
        </div>
    )
}
