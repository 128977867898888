import Compressor from 'compressorjs';
import { storage } from './firebase';
import firebase from 'firebase/app';
import { getStorageUrl } from './config-reader';

const compressFile = async (file: Blob | File) => {
  const result = await new Promise((resolve, reject) => {
    new Compressor(file, {
      success: resolve,
      error: reject
    });
  });

  return result;
};

const uploadToStorage = async (
  compressedFile: Blob,
  uploadFilename: string
) => {
  if (uploadFilename) {
    const result = await uploadFileAysnc(uploadFilename, compressedFile);
    return result;
  }
};

const uploadFileAysnc = async (filePath: string, experienceBlob: Blob) => {
  const result = buildResult(
    false,
    'Sorry. There was a problem uploading your logo.',
    ''
  );

  try {
    const uploadResult = await storage
      .ref()
      .child(filePath)
      .put(experienceBlob);

    if (uploadResult) {
      const progress =
        (uploadResult.bytesTransferred / uploadResult.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (uploadResult.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;

        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');

          break;

        case firebase.storage.TaskState.SUCCESS:
          console.log('Success');
          result.success = true;
          result.downloadUrl = await uploadResult.ref.getDownloadURL();
          result.message = '';

          return result;
      }
    }
  } catch (Error) {
    console.error(Error);

    return result;
  }

  return result;
};

const downloadFileAysnc = async (urlIn: string) => {
  const response = {
    success: false,
    data: new Blob(),
    message: ''
  };

  try {
    const previewBaseUrl = getStorageUrl();

    const url = previewBaseUrl;

    const fileReference = storage.refFromURL(url);

    const downloadUrl = await fileReference.child(urlIn).getDownloadURL();

    if (downloadUrl.length > 0) {
      const experienceResponse = await fetch(downloadUrl);
      if (experienceResponse) {
        if (experienceResponse.status === 200) {
          const data = await experienceResponse.blob();

          response.data = data;
          response.success = true;
          return response;
        } else {
          response.message = 'HTTP error status: ' + experienceResponse.status;
        }
      }
    }
  } catch (Error) {
    response.message = 'There was an error retrieving the file at: ' + urlIn;
    return response;
  }

  response.message = 'There was an error retrieving the file at: ' + urlIn;
  return response;
};

const buildResult = (success: boolean, message: string, url: string) => {
  return {
    success: success,
    message: message,
    downloadUrl: url
  };
};

export { compressFile, uploadToStorage, downloadFileAysnc };
