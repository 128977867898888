import { iconColor } from "src/config";
import UploadImageFileModel from "./UploadImageFileModel";
import UploadVideoFileModel from "./UploadVideoFileModel";
import { v4 as uuidv4 } from 'uuid';

export interface TrackableSpec {
  id: string;
  name: string;
  url: string;
  // Index of video array?
  video?: number;
}

export interface VideoSpec {
  id: string;
  name: string;
  url: string;
  three60: boolean;
  muted: boolean;
}

const today = Date.now();

export default class ExperienceModel {
  uuid = uuidv4();
  type = "";
  title = "";
  trackables: UploadImageFileModel[] = [];
  videos: UploadVideoFileModel[] = [];
  images?: UploadVideoFileModel[];
  startdate?: number = today;
  email = "";
  downloadUrl = "";   // Not sure what that is
  filePath = "";
  img1 = "";
  brandColor = iconColor;
  duration = 366;
}