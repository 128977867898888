import React from "react";
//import 'src/components/image-upload/node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../file-upload-button/FileUploadButton.css'

interface IProps {
    onFileSelected: ((e: any) => void),
    buttonText: string,
    fileTypes: string,
    buttonId: string
}

export const FileUploadButton: React.FC<IProps> = ({ onFileSelected, buttonText, fileTypes, buttonId }) => {
  return (
    <div>
      <label htmlFor={buttonId} className="uploadButton">
          {buttonText}
      </label>
      <input
        type="file"
        id={buttonId}
        accept={fileTypes}
        style={{ display: "none", width: "100%" }}
        onChange={onFileSelected}
      />
    </div>
  );
}