import React, { Component } from 'react';
import '../../App.css';

import './styles/upload-image.css'

import { FileUploadButton } from '../file-upload-button/FileUploadButton';

import UploadImageFileModel from 'src/models/UploadImageFileModel';
import { v4 as uuidv4 } from 'uuid';
import { iconColor } from 'src/config';

interface IProps {
  addIllustration: (illustration: UploadImageFileModel) => void;
  createExperienceStep: string;
  setCreateExperienceStep: (step: string) => void;
  setAddingMoreTargetImages: (addingImage: boolean) => void;
}

interface IState {
  selectedFile: any,
  selectedFileDisplay: any;
  croppedFile: any;
  message: string;
  messageColor: string;
  uploaded: boolean;
  imageName: string;
  imageHeight: number;
  imageWidth: number;
}

class UploadImage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedFile: null,
      selectedFileDisplay: null,
      message: "",
      messageColor: "",
      uploaded: false,
      croppedFile: null,
      imageName: "",
      imageHeight: 0,
      imageWidth: 0
    };

    this.handelFileSelected = this.handelFileSelected.bind(this);
    this.resetState = this.resetState.bind(this);
    this.setCroppedImage = this.setCroppedImage.bind(this);
    this.addIllustrationHandler = this.addIllustrationHandler.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
  }

  setImageWidth(width: number) {
    this.setState({ imageWidth: width });
  }

  setImageHeight(height: number) {
    this.setState({ imageHeight: height });
  }

  setCroppedImage(file: any) {
    this.setState({ croppedFile: file });
    // this.setState({ selectedFile: file });
    // TODO - cropped image is different from the file object expected   
    // this.setState({ selectedFile: file });
    // if (file !== null && file !== undefined) {
    //   var url = window.URL.createObjectURL(file);
    //   this.setState({ selectedFileDisplay: url });
    // } else {
    //   this.setState({ selectedFileDisplay: null });
    // }
  }

  handleNameChange(e: any) {
    e.preventDefault();
    this.clearMessage();

    const newName = e.target.value;
    this.setState({ imageName: newName })
  }

  handelFileSelected(event: any) {
    this.setState({ message: "" })
    this.clearMessage();


    const file = event.target.files[0];
    const url = window.URL.createObjectURL(file);

    if (file) {
      const fileType = file.type;
      const ext = fileType.substr(fileType.length - 4);

      switch (ext) {
        case '/jpg':
        case 'jpeg':
          // case '/png':  // png is not working at the moment
          this.setState({ selectedFile: file });
          this.setState({ selectedFileDisplay: url });
          if (!this.state.imageName)
            this.setState({ imageName: file.name.split('.').slice(0, -1).join('.') })
          break;
        default:
          if (fileType.includes('image')) {
            this.setState({ message: "Format not accepted. Images must be .jpg" })
            this.setState({ selectedFileDisplay: null });
          } else {
            this.setState({ message: "Format not accepted." })
            this.setState({ selectedFileDisplay: null });
          }
      }
    }

    // if (file !== null && file !== undefined) {
    // this.setState({ selectedFile: file });
    //   var url = window.URL.createObjectURL(file);
    //   this.setState({ selectedFileDisplay: url });
    // } 

    // else {
    //   this.setState({ selectedFileDisplay: null });
    // }
  }












  resetState() {
    this.setState({ messageColor: "" });
    this.setState({ message: "" });
    this.setState({ selectedFileDisplay: null });
    this.setState({ selectedFile: null });
    this.setState({ croppedFile: null });
    this.setState({ imageName: "" });
  }

  addIllustrationHandler() {
    this.clearMessage();

    if (this.state.imageName.length === 0) {
      this.setState({ message: "Please enter an target-image name." });
      this.setState({ messageColor: "red" });
      return;
    }

    if (this.state.selectedFile === null) {
      this.setState({ message: "Please select a file of your target0image." });
      this.setState({ messageColor: "red" });
      return;
    }

    const illustrationId = uuidv4();

    const model = new UploadImageFileModel();
    model.id = illustrationId;
    model.name = this.state.imageName;
    model.selectedFile = this.state.selectedFile;
    model.selectedFilDisplay = this.state.selectedFileDisplay

    this.props.addIllustration(model);
    this.props.setAddingMoreTargetImages(false);
    this.resetState();
    // this.props.setCreateExperienceStep('filters');
  }

  clearMessage() {
    this.setState({ message: "" });
    this.setState({ messageColor: "" });
  }

  render() {
    return (
      <div className="uploadImageContainer">
        <div className="imageTargetContainer">
          <p>Target-Image</p>
          <div className="uploadImageTargetContainer">
            {this.state.selectedFileDisplay ? (
              <>
                <img
                  style={{
                    borderRadius: '5px',
                    objectFit: 'cover',
                    height: 'inherit',
                    widows: 'inherit'
                  }} src={this.state.selectedFileDisplay} alt="Target"
                />
                <button>
                  <svg width="25" height="25" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="9" fill="#F8F8F8" />
                    <path d="M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM12.001 17.765C11.9241 17.8404 11.8268 17.8915 11.7211 17.9121C11.6155 17.9327 11.5061 17.9218 11.4065 17.8808C11.307 17.8399 11.2217 17.7705 11.1611 17.6815C11.1006 17.5925 11.0675 17.4876 11.066 17.38V16.5H11C9.592 16.5 8.184 15.961 7.106 14.894C6.35913 14.1453 5.84369 13.1974 5.62131 12.1635C5.39893 11.1296 5.47902 10.0536 5.852 9.064C6.061 8.503 6.798 8.36 7.216 8.789C7.458 9.031 7.513 9.383 7.403 9.691C6.897 11.055 7.183 12.639 8.283 13.739C9.053 14.509 10.065 14.872 11.077 14.85V13.816C11.077 13.321 11.671 13.079 12.012 13.431L13.794 15.213C14.014 15.433 14.014 15.774 13.794 15.994L12.001 17.765ZM14.784 13.222C14.6703 13.105 14.5923 12.958 14.5592 12.7982C14.5261 12.6385 14.5392 12.4726 14.597 12.32C15.103 10.956 14.817 9.372 13.717 8.272C12.947 7.502 11.935 7.128 10.934 7.15V8.184C10.934 8.679 10.34 8.921 9.999 8.569L8.206 6.798C7.986 6.578 7.986 6.237 8.206 6.017L9.988 4.235C10.0649 4.15963 10.1622 4.10847 10.2679 4.08788C10.3735 4.06729 10.4829 4.07816 10.5825 4.11915C10.682 4.16014 10.7673 4.22945 10.8279 4.31848C10.8884 4.40751 10.9215 4.51235 10.923 4.62V5.511C12.353 5.489 13.794 6.006 14.883 7.106C15.6299 7.85469 16.1453 8.80264 16.3677 9.83652C16.5901 10.8704 16.51 11.9464 16.137 12.936C15.928 13.508 15.202 13.651 14.784 13.222V13.222Z" fill={iconColor} />
                  </svg>
                  <FileUploadButton
                    onFileSelected={this.handelFileSelected}
                    buttonText=""
                    fileTypes=".jpg, .jpeg"
                    buttonId="file-upload"
                  />
                </button>

              </>
            ) : (
              <>
                <svg width="40" height="40" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.92811 21.214C6.05054 19.3398 4.99545 16.7959 4.99545 14.143C4.99545 11.4901 6.05054 8.94609 7.92811 7.0719L8.06953 6.93048C10.9613 4.15438 15.2309 3.37228 18.9189 4.9431C22.6069 6.51391 25.0013 10.1344 25.0033 14.143C25.005 18.1894 22.5679 21.8381 18.8294 23.3862C15.0908 24.9344 10.7877 24.077 7.92811 21.214ZM14.0022 15.14V18.8028H15.9962V15.14H19.659V13.1459H15.9962L15.9962 9.48313H14.0022L14.0022 13.1459L10.3393 13.1459V15.14L14.0015 15.1407L14.0022 15.14Z" fill={iconColor} />
                </svg>
                <FileUploadButton
                  onFileSelected={this.handelFileSelected}
                  buttonText=""
                  fileTypes=".jpg, .jpeg"
                  buttonId="file-upload"
                />
              </>

            )}


          </div>
        </div>

        <div className="imageTargetDetails">
          <p>Target-Image Name</p>
          <div className="imageTargetNameContainer">
            <input
              placeholder="Target-Image Name"
              aria-label="Username"
              aria-describedby="basic-addon1"
              type='text'
              value={this.state.imageName}
              onChange={this.handleNameChange}
            />

            {this.state.message.length > 0 && (
              <div className="errorMessage">
                {this.state.message}
              </div>
            )}

            <button onClick={this.addIllustrationHandler}>ADD</button>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadImage;
