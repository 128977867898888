import firebase from 'firebase/app';

export default class UserModel {
  id = "";
  name = "";
  firstName = "";
  lastName = "";
  email = "";
  loginProvider = "";
  accessToken = "";
  firebaseUser: firebase.User | null = null;
  stripeRole?: string;
}
